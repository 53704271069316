import React from 'react';
import { Toaster, Toast } from '@blueprintjs/core';

import { REMOVE_NOTIFICATION, useNotifications } from './Notifications.context';

const Notifications = () => {
  const { dispatch, notifications } = useNotifications();

  const handleDismiss = (id) => {
    dispatch({ type: REMOVE_NOTIFICATION, payload: { id } });
  };

  const renderMessage = ({ title, content }) => (
    <>
      {title && (
        <>
          <strong>{title}</strong>
          <br />
        </>
      )}
      {content}
    </>
  );

  return (
    notifications &&
    notifications.length > 0 && (
      <Toaster position="bottom-right">
        {notifications.map((notification) => (
          <Toast
            key={notification.id}
            message={renderMessage(notification.message)}
            timeout={notification.timeout}
            intent={notification.intent}
            icon={notification.icon}
            onDismiss={() => handleDismiss(notification.id)}
          />
        ))}
      </Toaster>
    )
  );
};

export default Notifications;
