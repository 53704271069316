// vendors
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PopoverNotification from '../../../../components/PopoverNotification';
import 'styled-components/macro';

// components
import TextEditor from '../TextEditor';

// styles
import { Content } from './RenderContent.styles';
import leftFillNum from '../../../../utils/leftFillNum';
import { useDispatch } from 'react-redux';
import { HANDLE_KEY_STROKES } from '../../../../states/Actions/translation';
import { useVideoPlayer } from '../../../../components/VideoPlayer';

const RenderContent = ({
  index,
  content,
  pauseOnTyping,
  totalChildren,
  isActive,
  // onClick,
}) => {
  const [activeHoldTimeoutId, setActiveHoldTimeoutId] = useState(null);
  const dispatch = useDispatch();
  const lex = useSelector((state) => state.translation.data.lexique);
  const lexical = lex.map(item => item.word);

  const { playVideoWithDuration } = useVideoPlayer();

  const lineNumber = useMemo(
    () => leftFillNum(index + 1, totalChildren.toString().length),
    [index, totalChildren]
  );

  // Sync video progress to the start time of selected cell
 const handleSelectedCell = useCallback(() => {
    if(isActive) return;

    const startTime = content.start;
    const endTime = content.end;

    playVideoWithDuration(startTime, endTime);
 }, [content.start, content.end, playVideoWithDuration, isActive]);


  const customKeyBinding = useCallback(
    (event) => {
      if (event.type === 'keydown') {
        setActiveHoldTimeoutId(
          setTimeout(() => {
            dispatch({
              type: HANDLE_KEY_STROKES,
            });
          }, 80)
        );
        clearTimeout(activeHoldTimeoutId);
      }
    },
    [activeHoldTimeoutId, dispatch]
  );

  const { text, originalText } = content;

  return (
    <Content
      css={`
        border: 1px solid rgb(238, 238, 238);
        border-top-width: ${index === 0 ? '1px' : '2px'};
        border-bottom-width: ${index === totalChildren - 1 ? '1px' : '0'};
      `}
    >
      <div
        css={`
          display: grid;
          grid-template-columns: 5px auto;
          grid-gap: 0.5rem;
        `}
      >
        <div
          css={`
            background-color: ${text.length === 0
              ? 'hsl(var(--color-yellow-hsl))'
              : isActive
              ? 'hsl(var(--color-green-hsl))'
              : ''};
          `}
        />
        <div
          css={`
            display: grid;
            grid-template-rows: 1fr 1fr 1fr;
            justify-content: center;
            min-width: 24px;
            padding: 10px 0;
          `}
        >
          <div
            onClick={handleSelectedCell}
            css={`
              font-family: 'Open Sans';
              font-size: 1rem;
              font-weight: 600;
              color: hsl(var(--color-charcoal-h), var(--color-charcoal-s), 40%);
              cursor: pointer;
            `}
          >
            {lineNumber}
          </div>
          <div
            css={`
              position: relative;
              align-self: center;
              display: flex;
              justify-content: center;
              align-items: center;

              .bp3-overlay {
                position: absolute;
                left: 25px;
              }
            `}
          >
            {text.length === 0 ? (
              <PopoverNotification
                title="Missing translation."
                textContent="Insert at least one character."
              />
            ) : Math.abs(originalText.length - text.length) / originalText.length >
              0.3 ? (
              <PopoverNotification
                title="Translation Warning."
                textContent="The original sentence is different by at least 30%. Are you sure you want to translate this sentence like this?"
              />
            ) : null}
          </div>
          <div></div>
        </div>
      </div>

      <div
        css={`
          position: relative;
          min-height: 100%;
          max-height: 110px;
          padding: 8px 1rem;
          overflow: hidden auto;

          color: ${isActive
            ? `hsl(var(--color-green-h), var(--color-green-s), 20%)`
            : `hsl(var(--color-charcoal-h), var(--color-charcoal-s), 50%)`};
          font-size: 0.875rem;
          line-height: 1.7;
          letter-spacing: 0.35px;
          white-space: pre-wrap;

          user-select: none;
          cursor: not-allowed;
        `}
      >
        {originalText}
      </div>

      <TextEditor
        index={index}
        text={text}
        pauseOnTyping={pauseOnTyping}
        onClick={handleSelectedCell}
        onKeyDown={customKeyBinding}
        focused={isActive}
        focusToEnd
        lexical={lexical}
        css={`
          position: relative;
          padding: 8px 0 8px 1rem;
          border-left: 2px solid rgb(238, 238, 238);
          font-size: 0.875rem;
          cursor: text;
        `}
      />
    </Content>
  );
};

RenderContent.propTypes = {
  index: PropTypes.number.isRequired,
  content: PropTypes.shape({
    text: PropTypes.string,
    originalText: PropTypes.string,
  }).isRequired,
  totalChildren: PropTypes.number.isRequired,
  pauseOnTyping: PropTypes.bool,
  isActive: PropTypes.bool,
  // onClick: PropTypes.func,
};
RenderContent.defaultProps = {
  pauseOnTyping: false,
  isActive: false,
  // onClick: () => {},
};

export default React.memo(RenderContent);
