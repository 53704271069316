import styled from 'styled-components';

export const Container = styled.section`
  display: grid;
  /* grid-template-columns: 2fr 3.4fr 1.67fr; */
  grid-template-columns: 1fr 2fr 1fr;
  width: 100%;
  max-width: 1414px;
  margin: 2rem auto 0;
`;
