import {
  FETCH_CORRECT_DATA_BEGIN,
  FETCH_CORRECT_DATA_FAILURE,
  FETCH_CORRECT_DATA_SUCCESS,
} from '../Actions';

const initialState = {
  data: {
    content: '',
    corrections: [],
  },
  status: 'idle',
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CORRECT_DATA_BEGIN:
      return {
        ...state,
        status: 'loading',
      };

    case FETCH_CORRECT_DATA_SUCCESS:
      const corrections = action.payload.corrections?.map((correction, index) => ({
        ...correction,
        id: index,
      }));

      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          corrections,
        },
        status: 'succeeded',
      };

    case FETCH_CORRECT_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        status: 'failed',
      };

    default:
      return state;
  }
};

export default reducer;
