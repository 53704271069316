// vendors
import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Editor as DraftEditor,
  EditorState,
  CompositeDecorator,
  ContentState,
  getDefaultKeyBinding,
} from 'draft-js';

// utils
import { getSelectionPosition, isCurrentTextEmpty } from './Editor.utils';
import {
  addSuggestion,
  getMatch,
  getSuggestions,
  isFirstLetterCapitalized,
  capitalizeFirstLetter,
  // getAutocompleteStrategy,
} from './Suggestions/Suggestions.utils';
import {
  createCorrectionsEntities,
  getErrorCorrectionStrategy,
} from './ErrorCorrection/ErrorCorrection.utils';

// context
import { useEditor } from './Editor.context';

// components
import Suggestions from './Suggestions';
import './editor.css';
// import Suggestion from './Suggestions/Suggestion';
import ErrorCorrection from './ErrorCorrection';
import { LineNumberBlock } from '../LineNumberBlock/LineNumberBlock';
// import { useSelector } from 'react-redux';

export const Container = styled.div`
  position: relative;
  background-color: var(--color-white);
  min-height: 100%;
  font-size: 1rem;
  letter-spacing: 0.35px;
  line-height: 1.7;
  cursor: text;
  padding-left: 10% !important;
`;

const Toolbar = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  padding: 0.15rem 3rem;
  position: fixed;
  width: 100%;

  button {
    cursor: pointer;
    padding: 1rem;
    min-width: 125px;
    border-radius: 5px;
    color: white;
    --color-green-s: 64%;
    background-color: hsl(
      var(--color-green-h),
      var(--color-green-s),
      var(--color-green-l)
    );
    background-image: linear-gradient(
      45deg,
      hsl(var(--color-green-h), var(--color-green-s), 30%) 0%,
      hsl(var(--color-green-h), var(--color-green-s), 43%) 70%,
      hsl(var(--color-green-h), var(--color-green-s), var(--color-green-l)) 100%
    );
    border: 1px solid hsl(var(--color-green-h), var(--color-green-s), 30%);
    box-shadow: none;
  }
  button:hover {
    --color-green-s: 90%;
  }
`;

export const LineNumbersContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 7%;
  border-right: 1px solid black;
  pointer-events: none;
`;

const Editor = ({
  plainText,
  corrections,
  lexical,
  autocorrect,
  onPastedText,
  focusToEnd,
  customKeyBinding,
  customKeys,
  readOnly,
  onClick,
  children,
  evalChildren,
  isTranscription,
  ...rest
}) => {
  const { editorState, setEditorState, isErrorCorrectionPopoverOpened } =
    useEditor();
  const [autocompleteState, setAutocompleteState] = useState({
    match: null,
    selectedSuggestion: 0,
  });

  const [errorIndex, setErrorIndex] = useState(null);

  const editorRef = useRef(null);

  const emitClick = useCallback(() => {
    if (typeof onClick === 'function') onClick();
  }, [onClick]);

  /**
   * Initialize decorators for:
   * - error correction
   * - autocomplete
   * - autocorrect
   *
   * @returns {CompositeDraftDecorator}
   */
  const getDecorator = () => {
    const existingDecorators = editorState.getDecorator();

    const strategies = existingDecorators ? existingDecorators._decorators : [];

    if (corrections && corrections.length > 0) {
      const errorCorrectionStrategy = {
        strategy: getErrorCorrectionStrategy(),
        component: ErrorCorrection,
        props: { popoverClick },
      };
      strategies.push(errorCorrectionStrategy);
    }

    if (autocorrect && autocorrect.length > 0) {
      // TODO: Add autocorrect strategy here
    }

    return new CompositeDecorator(strategies);
  };

  const resetAutocompleteState = () => {
    setAutocompleteState({
      match: null,
      selectedSuggestion: 0,
    });
  };

  const updateAutocompleteState = () => {
    // Reset if text is empty
    if (isCurrentTextEmpty(editorState)) return resetAutocompleteState();

    // Reset if no match found
    const match = getMatch();
    if (!match) return resetAutocompleteState();

    // Reset if no suggestions found
    const suggestions = getSuggestions(lexical, match?.text);
    if (!suggestions || suggestions.length === 0) return resetAutocompleteState();

    // Update position
    const position = getSelectionPosition();

    const newMatch = {
      ...match,
      suggestions,
      position,
    };

    // Update selectedSuggestion if too high
    let { selectedSuggestion } = autocompleteState;
    const lastSuggestionIndex = suggestions.length > 0 ? suggestions.length - 1 : 0;
    if (selectedSuggestion > lastSuggestionIndex) {
      selectedSuggestion = lastSuggestionIndex;
    }

    setAutocompleteState({
      match: newMatch,
      selectedSuggestion,
    });
  };

  const addEntityWithSelectedSuggestion = () => {
    const { match, selectedSuggestion } = autocompleteState;

    if (!match.suggestions[selectedSuggestion]) return;

    const item = match.suggestions[selectedSuggestion];
    const normalizedItem = isFirstLetterCapitalized(match.text)
      ? capitalizeFirstLetter(item)
      : item;

    handleSuggestionSelected(normalizedItem);
  };

  const focus = () => {
    isErrorCorrectionPopoverOpened
      ? editorRef.current?.blur()
      : editorRef.current?.focus();
  };

  const handleClick = () => {
    if (isTranscription){
      focus();
    }

    emitClick();
  };

  const handleChange = (newState) => {
    setEditorState(newState);
  };

  const handleSuggestionSelected = (item) => {
    const newEditorState = addSuggestion(editorState, item);

    handleChange(newEditorState);

    resetAutocompleteState();
  };

  const getSuggestionsCommandFromKeys = (event) => {
    if (event.key === 'Escape') {
      return 'close-suggestions';
    }

    if (event.key === 'ArrowDown' || (event.shiftKey && event.key === 'Tab')) {
      return 'select-previous';
    }

    if (event.key === 'Tab') {
      return 'select-next';
    }

    if (event.key === 'Enter') {
      return 'add-suggestion';
    }
  };

  const handleSuggestionsKeyCommand = (command) => {
    const { match, selectedSuggestion } = autocompleteState;

    if (command === 'close-suggestions') {
      resetAutocompleteState();
      return 'handled';
    }

    if (command === 'select-next') {
      const index =
        selectedSuggestion >= match.suggestions.length - 1
          ? 0
          : selectedSuggestion + 1;

      setAutocompleteState({
        ...autocompleteState,
        selectedSuggestion: index,
      });

      return 'handled';
    }

    if (command === 'select-previous') {
      const index =
        selectedSuggestion <= 0
          ? match.suggestions.length - 1
          : selectedSuggestion - 1;

      setAutocompleteState({
        ...autocompleteState,
        selectedSuggestion: index,
      });

      return 'handled';
    }

    if (command === 'add-suggestion') {
      addEntityWithSelectedSuggestion();

      return 'handled';
    }
  };

  const handleKeyBinding = (event) => {
    const { match } = autocompleteState;

    if (customKeyBinding) {
      return customKeyBinding(event, editorState);
    }

    if (customKeys) {
      customKeys(event, editorState);
    }

    if (match && match.suggestions.length > 0) {
      return getSuggestionsCommandFromKeys(event);
    }

    return getDefaultKeyBinding(event);
  };

  const handleKeyCommand = (command) => {
    handleSuggestionsKeyCommand(command);

    return 'not-handled';
  };

  const handleSearchError = (event) => {
    const currentCorrections = Array.from(
      document.querySelectorAll('a[role="button"]')
    );
    if (!currentCorrections || currentCorrections.length === 0) return;

    let nextIndex =
      event === 'next-error'
        ? errorIndex === null
          ? 0
          : errorIndex + 1
        : errorIndex - 1;

    if (nextIndex === currentCorrections.length) {
      nextIndex = 0;
    } else if (nextIndex < 0) {
      nextIndex = currentCorrections.length - 1;
    }
    setErrorIndex(nextIndex);

    if (!currentCorrections[nextIndex]) return;
    currentCorrections[nextIndex].click();
    currentCorrections[nextIndex].scrollIntoView();
  };
  const popoverClick = () => {
    const currentCorrections = Array.from(
      document.querySelectorAll('a[role="button"]')
    );
    const selectedPopover = document.querySelectorAll(
      'a.bp3-popover2-open.bp3-active'
    );
    if (!currentCorrections || !selectedPopover) return;
    const index = currentCorrections.findIndex(
      (error) => error === selectedPopover[0]
    );
    setErrorIndex(index);
  };

  // When component is mounted, we update editorState with our content and defined decorator(s).
  useEffect(() => {
    const decorator = getDecorator();

    // create ContentState from plain text
    let newContentState = ContentState.createFromText(plainText);
    // create ContentState from plain text with corrections when available
    if (corrections && corrections.length > 0) {
      newContentState = createCorrectionsEntities(plainText, corrections);
    }
    let newEditorState = EditorState.createWithContent(newContentState, decorator);

    // if (focusToEnd) {
    //   newEditorState = EditorState.moveFocusToEnd(newEditorState);
    // }

    setEditorState(newEditorState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // force moving focus to the end
    if (focusToEnd)
      setEditorState((prevState) => EditorState.moveFocusToEnd(prevState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update autocomplete state every time the editorState changed
  useEffect(() => {
    if (!editorState || !lexical || lexical.length === 0) return;

    updateAutocompleteState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState]);

  // useEffect(() => {
  // const { match } = autocompleteState;
  // Release the focus to the editor
  // requestAnimationFrame(() => focus());
  // }, []);

  const renderSuggestions = () => {
    const { match, selectedSuggestion } = autocompleteState;

    if (!match) return null;

    const { suggestions } = match;

    if (!suggestions || suggestions.length === 0) return null;

    return (
      <Suggestions
        {...match}
        selectedSuggestion={selectedSuggestion}
        onSelect={(val) => handleSuggestionSelected(val)}
        onClose={() => resetAutocompleteState()}
      />
    );
  };

  return (
    <>
      {corrections && corrections.length > 0 && (
        <Toolbar>
          <button
            onClick={() => {
              handleSearchError('previous-error');
            }}
          >
            Previous Error
          </button>
          <button
            onClick={() => {
              handleSearchError('next-error');
            }}
          >
            Next Error
          </button>
        </Toolbar>
      )}

      <Container onClick={handleClick} onFocus={handleClick} {...rest}>
        <LineNumbersContainer>
          <LineNumberBlock editorState={editorState} />
        </LineNumbersContainer>
        <DraftEditor
          ref={editorRef}
          editorState={editorState}
          keyBindingFn={handleKeyBinding}
          handleKeyCommand={handleKeyCommand}
          handlePastedText={onPastedText}
          onChange={handleChange}
          // onFocus={handleClick}
          readOnly={readOnly}
          stripPastedStyles={true}
        />

        {renderSuggestions()}
      </Container>
    </>
  );
};

export default Editor;

Editor.propTypes = {
  plainText: PropTypes.string,
  lexical: PropTypes.arrayOf(PropTypes.string),
  autocorrect: PropTypes.arrayOf(PropTypes.shape({})),
  corrections: PropTypes.arrayOf(PropTypes.shape({})),
  onPastedText: PropTypes.func,
  focusToEnd: PropTypes.bool,
  readOnly: PropTypes.bool,
  customKeyBinding: PropTypes.func,
  onClick: PropTypes.func,
};

Editor.defaultProps = {
  plainText: '',
  lexical: [],
  autocorrect: [],
  corrections: [],
  onPastedText: undefined,
  focusToEnd: false,
  readOnly: false,
  customKeyBinding: undefined,
  onClick: () => {},
};
