// vendors
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import 'styled-components/macro';

// actions
import { editData } from '../../../states/Actions';

// contexts
import { useEditor, withEditorProvider } from '../../../components/Editor/Editor.context';

// components
import Editor from '../../../components/Editor';

// utils
import { handlePastedText } from '../../TranscriptionView/EditorSection/EditorSection';

// styles
import { hideVisuallyStyle } from '../../../styles/global';
import { Container } from './EditorSection.styles';
import { useNotifications } from '../../../components/Notifications/Notifications.context';
import { getDefaultKeyBinding } from 'draft-js';

const customKeyBinding = (event, editorState) => {
  if (event.key === 'Backspace') {
    const selection = editorState.getSelection();
    const start = selection.getStartOffset();

    if (start === 0) {
      event.preventDefault();
      return;
    }
  }

  if (event.key === 'Enter') {
    event.preventDefault();
    return;
  }

  return getDefaultKeyBinding(event);
};

const EditorSection = ({ content, isTranslation, ...rest }) => {
  const dispatch = useDispatch();

  const { dispatch: notificationsDispatcher } = useNotifications();

  const corrections = useSelector((state) => state.correction.data.corrections);

  // const lexical = useSelector((state) => state.transcriptionData.data.lexique);

  const { editorState } = useEditor();
  const accessMode = useSelector((state) => isTranslation ? state.translation.data.accessmode : state.transcription.data.accessmode);

  /**
   * Update data content
   *
   * We need to use a useEffect here instead of an event on change
   * to ensure having the last text value from the editor.
   */
  useEffect(() => {
    if (!editorState) return;

    const currentContent = editorState.getCurrentContent();
    const plainText = currentContent.getPlainText();

    if (plainText && plainText !== content) {
      dispatch(editData({ content: plainText, isTranslation }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState]);

  return (
    <Container aria-labelledby="accessible-editor-correct" {...rest}>
      <h2 id="accessible-editor-correct" css={hideVisuallyStyle}>
        Éditeur texte de correction
      </h2>

      <div />
      <Editor
        plainText={content}
        corrections={corrections}
        onPastedText={handlePastedText(notificationsDispatcher, accessMode, isTranslation)}
        customKeyBinding={isTranslation && customKeyBinding}
        // lexical={lexical}
        css={`
          padding: 1.5rem 1.25rem 2rem;
          box-shadow: 0 1px 2px var(--shadow-color);
        `}
      />
      <div
        css={`
          padding: 1em;
          overflow: auto;
          height: calc(100vh - var(--header-height));
        `}
      >
        {/* <pre>
          <code>{JSON.stringify(data, null, 2)}</code>
        </pre> */}
      </div>
    </Container>
  );
};

EditorSection.propTypes = {
  content: PropTypes.string.isRequired,
  isTranslation: PropTypes.bool,
};

EditorSection.defaultProps = {
  isTranslation: false,
};

export default withEditorProvider(EditorSection);
