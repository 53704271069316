// vendors
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'styled-components/macro';

// states
import {
  fetchTranscriptionData,
  setTranscriptionFetched,
  setCurrentStep,
} from '../../states/Actions';
import { step } from '../../states/Reducers/app';

// contexts
import { useNotifications } from '../../components/Notifications/Notifications.context';

// components
import VideoPlayer from '../../components/VideoPlayer';
import Shortcuts from '../../components/Shortcuts';

// views
import LoadingView from '../LoadingView';
import EditorSection from './EditorSection';
import NotesSection from './NotesSection';
import SuggestionsSection from './SuggestionsSection';

// styles
import {
  Container,
  // PlayerContainer,
  PlayerSection,
  ToolbarGroup,
  ToolbarSection,
  Switch,
} from './TranscriptionView.styles';

const TranscriptionView = () => {
  const [pauseOnTyping, setPauseOnTyping] = useState(false);
  const { dispatch: notificationsDispatcher } = useNotifications();
  // const { seekToSeconds, duration, progress } = useVideoPlayer();
  const dispatch = useDispatch();

  const loadingStatus = useSelector((state) => state.transcription.status);

  const isFetched = useSelector((state) => state.transcription.isFetched);
  // const videoTimeStamp = useSelector(
  //   (state) => state.transcriptionData.data.videotimestamp
  // );
  const videoSrc = useSelector((state) => state.transcription.data.videolink);
  const videoExists = useSelector((state) => state.transcription.data.videoExists);
  const audioSrc = useSelector((state) => state.transcription.data.audiofile);

  const finalUrl = videoExists ? videoSrc : audioSrc;

  const lexique = useSelector((state) => state.transcription.data.lexique);

  const notes = useSelector((state)=> state.transcription.data.notes);

  const suggestions = useSelector((state)=> state.transcription.data.suggestions);

  const handleClickPauseOnTyping = useCallback(
    () => setPauseOnTyping((prev) => !prev),
    []
  );

  const location = useLocation();
  const [searchParams] = useSearchParams();

  // fetch transcription data from api **only once**
  useEffect(() => {
    const id = location?.pathname?.split('/').filter((p) => p)[0];
    const token = searchParams.get('token');

    // dispatch(setInitialStep(step.TRANSCRIPTION));

    // set transcription as default step
    dispatch(
      setCurrentStep({
        name: step.TRANSCRIPTION,
        path: `/${id}?token=${token}`,
      })
    );

    dispatch(
      fetchTranscriptionData(notificationsDispatcher, {
        id,
        token,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // reset isFetched flag value
  useEffect(() => {
    if (!isFetched) return;

    dispatch(setTranscriptionFetched(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]);

  // // update last video progress position
  // useEffect(() => {
  //   if (!progress || !progress.playedSeconds) return;

  //   dispatch(updateTimeStamp(progress.playedSeconds));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [progress]);

  // // seek video time from shared url with time parameter if exist
  // // or last saved video progress position
  // useEffect(() => {
  //   if (!duration) return;

  //   if (window.location.href.includes('&time=')) {
  //     const splittedUrl = window.location.href.split('&time=');
  //     const time = Number(splittedUrl[1]);

  //     seekToSeconds(time);
  //     return;
  //   }

  //   if (!videoTimeStamp) return;

  //   seekToSeconds(videoTimeStamp);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [duration]);

  if (loadingStatus === 'loading') return <LoadingView />;

  return (
    <Container>
      <EditorSection pauseOnTyping={pauseOnTyping} />

      <PlayerSection>
        <VideoPlayer src={finalUrl} videoExists={videoExists} />

        <ToolbarSection>
          <ToolbarGroup>
            <Switch
              label="Pause while typing"
              checked={pauseOnTyping}
              onChange={handleClickPauseOnTyping}
            />
          </ToolbarGroup>

          <div>
            <Shortcuts />
          </div>
        </ToolbarSection>
      </PlayerSection>
      {suggestions && suggestions.length !== 0 ? (
        <div>
          {/* <SuggestionsSection suggestions={['a','b',]}></SuggestionsSection> */}
          <SuggestionsSection suggestions={suggestions}></SuggestionsSection>
        </div>
      ) : null}
      {(notes.length > 0 || (lexique.length > 0 && lexique[0] !== '')) && (
        <NotesSection notes={notes} lexiques={lexique} />
      )}
    </Container>
  );
};

export default TranscriptionView;
