import styled, { css } from 'styled-components';
import { Colors } from '@blueprintjs/core';

export const selectedStyle = css`
  background-color: rgba(39, 194, 140, 0.25);

  :first-child {
    border-bottom-left-radius: 5px;
  }
`;

export const Nav = styled.nav`
  position: absolute;
  /* position: fixed; */
  display: flex;
  align-items: center;

  margin-top: 20px;
  padding: 0;

  line-height: 1.5;

  background-color: ${Colors.WHITE};

  border-radius: 5px;
  border-top-left-radius: 0px;

  box-shadow: 0px 0.8px 3.2px rgb(0 0 0 / 8%), 0px 1.9px 8.1px rgb(0 0 0 / 4%),
    0px 3.6px 16.5px rgb(0 0 0 / 2%), 0px 7.2px 33.9px rgb(0 0 0 / 1%),
    0px 20px 93px rgb(0 0 0 / 1%);

  z-index: 1000;
`;

export const List = styled.ol`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  > li + li {
    margin-left: -1px;

    ::before {
      position: absolute;
      left: 0px;
      width: 1px;
      height: 70%;
      background-color: ${Colors.LIGHT_GRAY2};
      content: '';
    }
  }
`;

export const ListItem = styled.li`
  position: relative;
  padding: 6px 10px;

  font-size: 0.825rem;

  cursor: pointer;

  :hover,
  :focus {
    ${selectedStyle}
  }

  ${({ $selected }) => $selected && selectedStyle}
`;

export const ButtonWrapper = styled.div`
  /* margin-top: 2px; */
`;
