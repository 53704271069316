import { useEffect, useState } from "react";
import styled from "styled-components";

const LineNumber = styled.div`
  position: absolute;
  left: 0;
  color: gray;
`;

export const LineNumberBlock = ({ editorState }) => {
  const [blockPositions, setBlockPositions] = useState([]);
  const contentState = editorState.getCurrentContent();
  const blocks = contentState.getBlocksAsArray();

  useEffect(() => {
    const newPositions = [];
    for (let i = 0; i < blocks.length; i++) {
      const block = blocks[i];
      const blockElement = document.querySelector(
        `[data-offset-key="${block.getKey()}-0-0"]`
      );
      newPositions.push(blockElement ? blockElement.offsetTop : null);
    }

    if (!arraysAreEqual(newPositions, blockPositions)) {
      setBlockPositions(newPositions);
    }
  }, [blocks, blockPositions]);

  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  }

  return (
    <div className="line-numbers">
      {blockPositions.map(
        (top, index) =>
          top !== null && (
            <LineNumber key={index} style={{ top: `${top}px` }}>
              {index + 1}
            </LineNumber>
          )
      )}
    </div>
  );
};
