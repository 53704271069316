// vendors
import styled from 'styled-components';
import { Switch as BpSwitch } from '@blueprintjs/core';

export const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(564px, 1fr) 2fr;

  /* min-height: 100%; */
  min-width: 100%;

  overflow: hidden;
`;

export const PlayerSection = styled.section`
  display: flex;
  flex-flow: column;

  max-height: calc(100vh - var(--header-height));
  padding: 1rem 1rem 0 0.18rem;

  overflow-y: auto;
`;

export const ToolbarSection = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 1rem 0;
`;

export const PlayerContainer = styled.div`
  width: 100%;
`;

export const ToolbarGroup = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
`;

export const Switch = styled(BpSwitch)`
  /* display: inline-block;
  margin-bottom: 0;

  &.bp3-switch input:checked ~ .bp3-control-indicator {
    background: #27c28c;
    background-image: linear-gradient(90deg, #27c28c 0%, #8ce880 100%);
  }

  &.bp3-switch:hover input:checked ~ .bp3-control-indicator {
    background: #1e996e;
    background-image: linear-gradient(90deg, #1e996e 0%, #53dc41 100%);
  } */
`;
