export default (str, truncateAt, useWordBoundary = false) => {
  const sliceBoundary = (sliced) =>
    sliced.substr(0, sliced.lastIndexOf(' ')).replace(/[\s.:,!?]+$/i, '');

  const result =
    str.length <= truncateAt
      ? str
      : `${
          useWordBoundary
            ? sliceBoundary(str.slice(0, truncateAt - 1))
            : str.substr(0, truncateAt - 1)
        }&hellip;`;

  return result;
};
