// vendors
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Virtuoso as List } from 'react-virtuoso';
import styled from 'styled-components';
import 'styled-components/macro';

// components
import RenderContent from './RenderContent';

// styles
import { hideVisuallyStyle } from '../../../styles/global';
import { Section, Wrapper } from './EditorSection.styles';
import { useVideoPlayer } from '../../../components/VideoPlayer';
import { setSearchValue } from '../../../states/Actions';

const Scroller = styled.div`
  > div {
    padding: 1.2rem 1rem 20rem;
  }

  > div > div {
    border-bottom: 1px solid rgb(238, 238, 238);

    /* Hack to add extra padding at the bottom of the virtualized list from react-virtuoso */
    ::after {
      position: relative;
      bottom: 0;
      left: 0;
      display: block;
      height: 1.5rem;
      width: 100%;
      content: '';
    }
  }
`;

const EditorSection = ({ pauseOnTyping, ...rest }) => {
  const listRef = useRef();
  const dispatch = useDispatch();
  const [isSearching, setIsSearching] = useState(false);

  const children = useSelector((state) => state.translation.data.children);
  const searchIndex = useSelector((state) => state.translation.search?.search);

  const { isPlaying, progress, seekToSeconds } = useVideoPlayer();

  const isActive = useCallback(
    (index) => {
      const current = children[index];
      const endTime = children[index + 1]
        ? children[index + 1].start
        : progress.playedSeconds + 0.0001;

      return (
        current.start < progress.playedSeconds + 0.0001 &&
        progress.playedSeconds <= endTime - 0.0001
      );
    },
    [children, progress.playedSeconds]
  );

  const activeIndex = useMemo(
    () => {
      return children.findIndex((_, i) => isActive(i));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [progress.playedSeconds]
  );

  // Sync video progress to the start time of found cell from the search
  useEffect(() => {
    if (searchIndex < 0 || children?.length <= 0) return;

    const child = children[searchIndex];

    if (!child) return;

    seekToSeconds(child.start + 0.0001);

    setIsSearching(true);

    dispatch(setSearchValue(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchIndex]);

  useEffect(() => {
    if (!listRef?.current || children?.length <= 0) {
      return;
    }

    if (isPlaying || isSearching) {
      listRef.current.scrollToIndex({
        index: activeIndex,
        align: 'center',
        behavior: 'auto',
      });

      setIsSearching(false);

      return;
    }

    listRef.current.scrollIntoView({
      index: activeIndex,
      behavior: 'auto',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  const RowItem = useCallback(
    (index) => (
      <div>
        <RenderContent
          index={index}
          content={children[index]}
          pauseOnTyping={pauseOnTyping}
          totalChildren={children.length}
          isActive={index === activeIndex}
          // onClick={handleClick}
        />
      </div>
    ),
    [activeIndex, children, pauseOnTyping]
  );

  return (
    <Section aria-labelledby="accessible-editor-translate" {...rest}>
      <h2 id="accessible-editor-translate" css={hideVisuallyStyle}>
        Translation Editor
      </h2>

      <Wrapper>
        <List
          ref={listRef}
          data={children}
          itemContent={RowItem}
          components={{ Scroller }}
          overscan={1000}
          style={{
            height: '100%',
          }}
        />
      </Wrapper>
    </Section>
  );
};

EditorSection.propTypes = {
  pauseOnTyping: PropTypes.bool,
};
EditorSection.defaultProps = {
  pauseOnTyping: false,
};

export default EditorSection;
