import styled from 'styled-components';

export const DropDown = styled.div`
  --color-gray-l: 27%;

  .bp3-popover2-content {
    background-color: hsl(
      var(--color-gray-h),
      var(--color-gray-s),
      var(--color-gray-l)
    );
  }
`;

export const DropDownContent = styled.div``;
