// vendors
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import 'styled-components/macro';

// actions
import {
  fetchData,
  fetchCorrectionData,
  setTranslationFetched,
  setTranscriptionFetched,
  setCurrentStep,
  // getFakeCorrectionsData,
} from '../../states/Actions';

// sections
import EditorSection from './EditorSection';

// components
import { useNotifications } from '../../components/Notifications/Notifications.context';
import LoadingView from '../LoadingView';

// styles
import { Container, Wrapper } from './CorrectionView.styles';
import Shortcuts from '../../components/Shortcuts';
import { step } from '../../states/Reducers/app';

const CorrectionView = () => {
  const [isFetchedOnce, setIsFetchedOnce] = useState(false);
  const dispatch = useDispatch();

  const { dispatch: notificationsDispatcher } = useNotifications();

  const transcriptionStatus = useSelector(
    (state) => state.transcription.status
  );
  const translationStatus = useSelector((state) => state.translation.status);
  const correctionStatus = useSelector((state) => state.correction.status);
  // const isCorrectionStep = useSelector(
  //   (state) => state.transcriptionData.isCorrectionStep
  // );
  const transcriptionContent = useSelector(
    (state) => state.transcription.data.content
  );
  const translationContent = useSelector(
    (state) => state.translation.data.content
  );

  const isTranslationFetched = useSelector(
    (state) => state.translation.isFetched
  );
  const isTranscriptionFetched = useSelector(
    (state) => state.transcription.isFetched
  );

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const isTranslation = location.pathname.includes(step.TRANSLATION);
  const pathnames = location?.pathname?.split('/').filter((p) => p);
  const id = isTranslation ? pathnames[1] : pathnames[0];

  const content = useMemo(() => {
    if (isTranslation) return translationContent;
    return transcriptionContent;
  }, [isTranslation, transcriptionContent, translationContent]);

  // fetch data from api only once
  useEffect(() => {
    dispatch(
      setCurrentStep({
        name: step.CORRECTION,
        path: isTranslation
          ? `/translation/${id}/correction?token=${token}`
          : `/${id}/correction?token=${token}`,
      })
    );

    dispatch(
      fetchData(notificationsDispatcher, {
        id,
        token,
        isTranslation,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // insure to get corrections data only once when content is available
  useEffect(() => {
    if (!content || isFetchedOnce) return;
    // dispatch(getFakeCorrectionsData(notificationsDispatcher, { content }));

    if (isTranslation && !isTranslationFetched) return;

    if (!isTranslation && !isTranscriptionFetched) return;

    dispatch(
      fetchCorrectionData(notificationsDispatcher, {
        id,
        token,
        content,
      })
    );

    isTranslation
      ? dispatch(setTranslationFetched(false))
      : dispatch(setTranscriptionFetched(false));

    setIsFetchedOnce(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, isTranslationFetched, isTranscriptionFetched]);

  const isLoading = useMemo(
    () =>
      transcriptionStatus === 'loading' ||
      translationStatus === 'loading' ||
      correctionStatus === 'loading',
    [correctionStatus, transcriptionStatus, translationStatus]
  );

  if (isLoading) return <LoadingView />;

  return (
    <Container>
      <Wrapper>
        <div
          css={`
            position: fixed;
            bottom: 0;
            left: 0;
            padding: 1rem;
          `}
        >
          <Shortcuts />
        </div>

        <EditorSection content={content} isTranslation={isTranslation} />
      </Wrapper>
    </Container>
  );
};

export default CorrectionView;
