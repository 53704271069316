import {
  EDIT_TRANSCRIPTION,
  FETCH_TRANSCRIPT_DATA_BEGIN,
  FETCH_TRANSCRIPT_DATA_FAILURE,
  FETCH_TRANSCRIPT_DATA_SUCCESS,
  RESET_EDIT_TRANSCRIPT_SINCE_LAST_SAVED,
  SAVE_TRANSCRIPT_DATA_BEGIN,
  SAVE_TRANSCRIPT_DATA_FAILURE,
  SAVE_TRANSCRIPT_DATA_SUCCESS,
  SAVE_TRANSCRIPT_DATA_URL_FAILURE,
  SAVE_TRANSCRIPT_DATA_URL_SUCCESS,
  IS_TRANSCRIPT_FETCHED,
  HANDLE_KEY_STROKES,
  HANDLE_KEY_STROKES_FROM_DB,
  SAVE_TRANSCRIPT_SUGGESTIONS,
} from '../Actions';

const initialState = {
  data: {
    title: '',
    videolink: '',
    videotimestamp: 0,
    ident: '',
    content: '',
    lexique: [],
    notes: [],
    finished: false,
    editSinceLastSaved: false,
    editeururl: '',
    keyStrokes: 0,
    keyStrokesFromDB : 0,
    suggestions: [],
  },
  status: 'idle',
  savingStatus: 'idle',
  isFetched: false,
  error: null,
  accessmode: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSCRIPT_DATA_BEGIN:
      return {
        ...state,
        status: 'loading',
      };

    case FETCH_TRANSCRIPT_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        status: 'succeeded',
      };

    case FETCH_TRANSCRIPT_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        status: 'failed',
      };

    case SAVE_TRANSCRIPT_DATA_BEGIN:
      return {
        ...state,
        error: null,
        savingStatus: 'loading',
      };

    case SAVE_TRANSCRIPT_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        savingStatus: 'succeeded',
      };

    case SAVE_TRANSCRIPT_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        savingStatus: 'failed',
      };

    case SAVE_TRANSCRIPT_DATA_URL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        error: null,
        savingStatus: 'succeeded',
      };

    case SAVE_TRANSCRIPT_DATA_URL_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
        error: null,
      };

    case RESET_EDIT_TRANSCRIPT_SINCE_LAST_SAVED:
      return {
        ...state,
        data: {
          ...state.data,
          editSinceLastSaved: false,
        },
      };

    case EDIT_TRANSCRIPTION:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
          editSinceLastSaved: true,
        },
      };

    case IS_TRANSCRIPT_FETCHED:
      return {
        ...state,
        ...action.payload,
      };

    case HANDLE_KEY_STROKES:
      return{
        ...state,
        data: {
          ...state.data,
          keyStrokes: action.payload,
        }
      }

    case HANDLE_KEY_STROKES_FROM_DB:
      return{
        ...state,
        data:{
          ...state.data,
          keyStrokesFromDB: action.payload
        }
      }

    case SAVE_TRANSCRIPT_SUGGESTIONS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

export default reducer;
