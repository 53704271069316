import { css } from 'styled-components';

const colorsStyle = css`
  --color-white: #fff;
  --color-black: #000;
  --color-charcoal: #343434;
  --color-dark-gray: #293742;
  --color-light-gray: #f5f8fa;
  --color-emerald-green: #41cf87;
  --color-mint-green: #acffb4;
  --color-light-green: #9ff07e;

  --color-charcoal-h: 0; /* hsl(0, 0%, 20%) */
  --color-charcoal-s: 0%;
  --color-charcoal-l: 20%;

  --color-gray-h: 206; /* hsl(206, 23%, 21%) */
  --color-gray-s: 23%;
  --color-gray-l: 21%;

  --color-green-h: 150; /* hsl(150, 60%, 53%) */
  --color-green-s: 60%;
  --color-green-l: 53%;

  --color-blue-h: 192; /* hsl(192, 100%, 41%) */
  --color-blue-s: 100%;
  --color-blue-l: 41%;

  --color-yellow-h: 38; /* hsl(38, 99%, 52%) */
  --color-yellow-s: 99%;
  --color-yellow-l: 52%;

  --color-orange-h: 23; /* hsl(23, 99%, 52%) */
  --color-orange-s: 99%;
  --color-orange-l: 52%;

  --color-red-h: 356; /* hsl(356, 100%, 56%) */
  --color-red-s: 100%;
  --color-red-l: 56%;

  --color-gray-hsl: var(--color-gray-h), var(--color-gray-s),
    var(--color-gray-l);

  --color-green-hsl: var(--color-green-h), var(--color-green-s),
    var(--color-green-l);

  --color-blue-hsl: var(--color-blue-h), var(--color-blue-s),
    var(--color-blue-l);

  --color-yellow-hsl: var(--color-yellow-h), var(--color-yellow-s),
    var(--color-yellow-l);

  --color-orange-hsl: var(--color-orange-h), var(--color-orange-s),
    var(--color-orange-l);

  --color-red-hsl: var(--color-red-h), var(--color-red-s), var(--color-red-l);
`;

export default colorsStyle;
