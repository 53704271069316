import styled from 'styled-components';
import { Switch as BpSwitch } from '@blueprintjs/core';
import { NavLink } from 'react-router-dom';

import vectorChevron from '../../images/icon-chevron.svg';

export const Container = styled.header`
  /* box-shadow: rgba(20, 26, 32, 0.6) 0px 2px 4px 0px; */
  box-shadow: rgba(0, 0, 0, 0.92) 0px 3px 4px 0px;
  z-index: 10;

  .bp3-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: var(--header-height);

    color: var(--color-white);
    background-color: var(--color-dark-gray);
  }
  .bp3-button {
    color: var(--color-white);
    margin: 0 5px;

    span {
      color: var(--color-white);
    }
  }

  .bp3-button.bp3-outlined {
    border: 1px solid var(--color-white);
  }

  .bp3-button.bp3-outlined:disabled,
  .bp3-button.bp3-outlined.bp3-disabled,
  .bp3-button.bp3-outlined:disabled:hover,
  .bp3-button.bp3-outlined.bp3-disabled:hover {
    border-color: rgba(255, 255, 255, 0.54);

    span {
      color: rgba(255, 255, 255, 0.54);
    }
  }

  .bp3-button:disabled .bp3-icon {
    color: rgba(255, 255, 255, 0.54) !important;
  }

  .bp3-button-group .bp3-button {
    margin-left: 0;
  }

  .bp3-button-group .bp3-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .bp3-button-group > .bp3-button {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .bp3-navbar-heading {
    display: inline-flex;
    height: 100%;
  }
`;

export const Logo = styled.h1`
  margin: 0;
`;

export const LogoImg = styled.img`
  width: 100%;
  height: 50px;
  max-width: 84px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  margin-left: 12px;
`;

export const Title = styled.h2`
  margin: 0;
  padding-left: 5px;

  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.33px;
`;

export const NavList = styled.ul`
  position: relative;
  display: flex;
  align-items: center;

  list-style: none;
  padding: 0;
  margin: 0;

  width: 100%;
  height: 100%;
  /* margin-left: 12px; */
`;

export const NavListItem = styled.li`
  display: flex;

  border-radius: 3px;
`;

export const Link = styled(NavLink)`
  position: relative;
  padding: 5px;

  color: rgba(255, 255, 255, 0.56);
  font-size: 1.15rem;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0.1px;

  border-radius: inherit;

  transition: background-color 0.25s, color 0.25s;

  ${NavListItem}:not(:last-child) & {
    ::after {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      margin-left: -13px;
      width: 13px;
      height: 13px;
      mask-image: url(${vectorChevron});
      mask-position: center;
      mask-repeat: no-repeat;
      background-color: rgba(255, 255, 255, 0.66);
      content: '';
    }

    > span {
      margin-right: 24px;
    }
  }

  &.active {
    color: rgba(255, 255, 255, 0.92);
  }

  :hover,
  :focus,
  &.active:hover,
  &.active:focus {
    text-decoration: none;

    background-color: hsla(var(--color-green-h), var(--color-green-s), 33%, 0.35);
    color: hsl(var(--color-green-h), var(--color-green-s), 86%);
  }
`;

export const DropdownWrapper = styled.div`
  padding: 1rem;
`;

export const Switch = styled(BpSwitch)`
  /* display: inline-block;
  margin: 0; */

  font-weight: 700;

  /* &.bp3-switch input:checked ~ .bp3-control-indicator {
    background: #27c28c;
    background-image: linear-gradient(90deg, #27c28c 0%, #8ce880 100%);
  }

  &.bp3-switch:hover input:checked ~ .bp3-control-indicator {
    background: #1e996e;
    background-image: linear-gradient(90deg, #1e996e 0%, #53dc41 100%);
  } */
`;
