import styled from 'styled-components';
import { greaterThan, lessThan } from '../../utils/mediaQuery';

export const Container = styled.div`
  display: flex;
  height: calc(100% - var(--header-height));

  ${lessThan(1440)} {
    flex-direction: column-reverse;
  }
`;

export const PlayerSection = styled.section`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1 1 10%;
  z-index: 1;
  overflow: hidden;

  width: 100%;

  ${lessThan(1440)} {
    max-width: var(--max-content-width);
    margin: 0 auto;
  }
`;

export const PlayerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-height: 100%;
  padding: 1rem;

  ${greaterThan(1440)} {
    padding-left: 4px;
  }
`;

export const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 1rem 0;
  /* padding: 0 1rem; */
`;

export const ToolbarGroup = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
`;
