import {
  EDIT_TRANSLATION,
  EDIT_TRANSLATION_LIST,
  FETCH_ORIGINAL_DATA_BEGIN,
  FETCH_ORIGINAL_DATA_FAILURE,
  FETCH_ORIGINAL_DATA_SUCCESS,
  FETCH_TRANSLATION_DATA_BEGIN,
  FETCH_TRANSLATION_DATA_FAILURE,
  FETCH_TRANSLATION_DATA_SUCCESS,
  RESET_EDIT_TRANSLATION_SINCE_LAST_SAVED,
  SAVE_TRANSLATION_DATA_BEGIN,
  SAVE_TRANSLATION_DATA_FAILURE,
  SAVE_TRANSLATION_DATA_SUCCESS,
  SAVE_TRANSLATION_DATA_URL_FAILURE,
  SAVE_TRANSLATION_DATA_URL_SUCCESS,
  IS_TRANSLATION_FETCHED,
  HANDLE_KEY_STROKES,
  HANDLE_KEY_STROKES_FROM_DB,
  HANDLE_BUTTON_CLICKS,
  SET_SEARCH_VALUE,
  UPDATE_SENTENCE_BLOCKS,
  // UPDATE_TRANSLATION_TIME_STAMP,
} from '../Actions/translation';

const initialState = {
  data: {
    title: '',
    videolink: '',
    videotimestamp: 0,
    ident: '',
    content: '',
    subtitles: [],
    original: {
      content: '',
      sentenceblocks: [],
      srt: '',
      subtitles: [],
    },
    children: [],
    lexique: [],
    notes: [],
    finished: false,
    editSinceLastSaved: false,
    editeururl: '',
    keyStrokes: 0,
    keyStrokesFromDB: 0,
    buttonclicks: 0,
    buttonclicksFromDB: 0,
    accessmode: '',
  },
  status: 'idle',
  originalDataStatus: 'idle',
  savingStatus: 'idle',
  isFetched: false,
  error: null,
  // typeOfEditor: '',
  search: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSLATION_DATA_BEGIN:
      return {
        ...state,
        status: 'loading',
      };

    case FETCH_TRANSLATION_DATA_SUCCESS: {
      const { sentenceblocks } = state.data.original;

      const children = sentenceblocks.map((block) => ({
        originalText: block.sentence || '',
        text: block.translated_sentence || '',
        start: block.start,
        end: block.end,
      }));

      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          children,
        },
        status: 'succeeded',
      };
    }

    case UPDATE_SENTENCE_BLOCKS: {
      const children = action.payload.map((block) => ({
        originalText: block.sentence || '',
        text: block.translated_sentence || '',
        start: block.start,
        end: block.end,
      }));
      return {
        ...state,
        data: {
          ...state.data,
          children,
        },
      };
    }

    case FETCH_TRANSLATION_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        status: 'failed',
      };

    case SAVE_TRANSLATION_DATA_BEGIN:
      return {
        ...state,
        error: null,
        savingStatus: 'loading',
      };

    case SAVE_TRANSLATION_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        savingStatus: 'succeeded',
      };

    case SAVE_TRANSLATION_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        savingStatus: 'failed',
      };

    case SAVE_TRANSLATION_DATA_URL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        error: null,
        savingStatus: 'succeeded',
      };

    case SAVE_TRANSLATION_DATA_URL_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
        error: null,
      };

    case FETCH_ORIGINAL_DATA_BEGIN:
      return {
        ...state,
        originalDataStatus: 'loading',
      };

    case FETCH_ORIGINAL_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          original: {
            ...action.payload,
          },
        },
        originalDataStatus: 'succeeded',
        // typeOfEditor: 'translation',
      };

    case FETCH_ORIGINAL_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        originalDataStatus: 'failed',
      };

    case RESET_EDIT_TRANSLATION_SINCE_LAST_SAVED:
      return {
        ...state,
        data: {
          ...state.data,
          editSinceLastSaved: false,
        },
      };

    case EDIT_TRANSLATION_LIST: {
      const children = [...state.data.children];

      const { content: text, index } = action.payload.data;

      const current = children[index] || '';

      children[index] = {
        ...current,
        text,
      };

      return {
        ...state,
        data: {
          ...state.data,
          children,
          editSinceLastSaved: true,
        },
      };
    }
    case EDIT_TRANSLATION:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
          editSinceLastSaved: true,
        },
      };

    case IS_TRANSLATION_FETCHED:
      return {
        ...state,
        ...action.payload,
      };

    case HANDLE_KEY_STROKES:
      return {
        ...state,
        data: {
          ...state.data,
          keyStrokes: (state.data.keyStrokes += 1),
        },
      };

    case HANDLE_KEY_STROKES_FROM_DB:
      return {
        ...state,
        keyStrokesFromDB: action.payload,
      };

    case HANDLE_BUTTON_CLICKS:
      return {
        ...state,
        data: {
          ...state.data,
          buttonclicks: (state.data.buttonclicks += 1),
        },
      };

    case SET_SEARCH_VALUE:
      return {
        ...state,
        search: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
