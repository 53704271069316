// vendors
import React from 'react';
import PropTypes from 'prop-types';
import Titlebar from '../Titlebar';

// styles
import { Container } from './Layout.styles';

/**
 * Main layout of the app
 */
const Layout = ({
  children,
  autoSave,
  onAutoSave,
  onSave,
  onNextStep,
  title,
  lastStepLoading,
}) => (
  <Container>
    <Titlebar
      title={title}
      autoSave={autoSave}
      onAutoSave={onAutoSave}
      onSave={onSave}
      onNextStep={onNextStep}
      lastStepLoading={lastStepLoading}
    />

    {children}
  </Container>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  autoSave: PropTypes.bool,
  onAutoSave: PropTypes.func,
  onSave: PropTypes.func,
  onNextStep: PropTypes.func,
  lastStepLoading: PropTypes.bool,
};
Layout.defaultProps = {
  autoSave: false,
  onAutoSave: () => {},
  onSave: () => {},
  onShare: () => {},
  onNextStep: () => {},
  lastStepLoading: false,
};

export default Layout;
