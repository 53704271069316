// vendors
import React from 'react';
import { Spinner } from '@blueprintjs/core';

// styles
import { Container } from './LoadingView.styles';

const LoadingView = () => {
  return (
    <Container>
      <Spinner />
    </Container>
  )
};

export default LoadingView;
