// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@blueprintjs/core';
import { Popover2 as Popover } from '@blueprintjs/popover2';

// styles
import { DropDown, DropDownContent } from './ButtonWithDropdown.styles';

const ButtonWithDropdown = ({
  buttonText,
  minimal,
  outlined,
  large,
  disabled,
  placement,
  children,
}) => {
  return (
    <DropDown>
      <Popover
        minimal={minimal}
        interactionKind="click"
        hasBackdrop={false}
        enforceFocus={false}
        usePortal={false}
        placement={placement}
        content={<DropDownContent role="menu">{children}</DropDownContent>}
        renderTarget={({ isOpen, ref, ...targetProps }) => (
          <Button
            {...targetProps}
            active={isOpen}
            outlined={outlined}
            large={large}
            elementRef={ref}
            text={buttonText}
            disabled={disabled}
            icon={isOpen ? 'chevron-up' : 'chevron-down'}
            aria-haspopup="true"
            aria-expanded={isOpen}
            aria-label="Options de sauvegarde"
          />
        )}
      />
    </DropDown>
  );
};

ButtonWithDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string,
  minimal: PropTypes.bool,
  outlined: PropTypes.bool,
  large: PropTypes.bool,
  disabled: PropTypes.bool,
  placement: PropTypes.string,
};
ButtonWithDropdown.defaultProps = {
  minimal: false,
  outlined: false,
  large: false,
  disabled: false,
  placement: undefined,
};

export default ButtonWithDropdown;
