// vendors
import React from 'react';
import PropTypes from 'prop-types';
import 'styled-components/macro';
import { Icon } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';

// hooks
import useHasMounted from '../../hooks/useHasMounted';

const PopoverNotification = ({ title, textContent, ...rest }) => {
  const hasMounted = useHasMounted();

  if (!hasMounted) {
    return null;
  }

  return (
    <Popover2
      minimal
      autoFocus={false}
      enforceFocus={false}
      openOnTargetFocus={false}
      usePortal={false}
      interactionKind="hover"
      placement="right"
      content={
        <div
          css={`
            display: flex;
            flex-direction: column;
            width: 100%;
            min-width: 200px;
            padding: 0.5rem;
            font-size: 0.75rem;
          `}
        >
          <span
            css={`
              font-weight: 700;
            `}
          >
            {title}
          </span>
          <span>{textContent}</span>
        </div>
      }
      renderTarget={({ isOpen, ref, ...targetProps }) => (
        <Icon
          {...targetProps}
          icon="warning-sign"
          size={16}
          css={`
            color: hsl(var(--color-yellow-h), var(--color-yellow-s), 40%);
            cursor: pointer;
          `}
        />
      )}
      {...rest}
    />
  );
};

PopoverNotification.propTypes = {
  title: PropTypes.string,
  textContent: PropTypes.string,
};

PopoverNotification.defaultProps = {
  title: '',
  textContent: '',
};

export default PopoverNotification;
