import styled from 'styled-components';

export const LinkButton = styled.a`
  padding: 1px 0 2px 0;

  &,
  :hover,
  :focus,
  :active,
  :visited {
    color: inherit;
    text-decoration: inherit;
    cursor: inherit;
  }

  position: relative;

  ::before,
  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* padding: 6px 0; */
    pointer-events: none;
  }

  ::before {
    border-bottom: 2px solid hsl(${({ theme }) => theme});
  }

  ::after {
    background: hsla(${({ theme }) => theme}, 0.15);
    /* transform: scaleY(0%); */
    /* transform-origin: bottom; */
    transition: transform 0.25s ease;
  }

  /* :hover::after {
    transform: scaleY(100%);
  } */
`;

// export const Content = styled.span`
//   position: relative;

//   ::before,
//   ::after {
//     content: '';
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     height: 100%;
//     width: 100%;
//     padding: 6px 0;
//   }

//   ::before {
//     border-bottom: 2px solid
//       hsl(var(--color-red-h), var(--color-red-s), var(--color-red-l));
//   }

//   ::after {
//     background: hsla(
//       var(--color-red-h),
//       var(--color-red-s),
//       var(--color-red-l),
//       0.15
//     );
//     transform: scaleY(100%);
//     transform-origin: bottom;
//     transition: transform 0.25s ease;
//   }

//   :hover::after {
//     transform: scaleY(100%);
//   }
// `;
