// vendors
import { EditorState, Modifier } from 'draft-js';
import Fuse from 'fuse.js';
import { getInsertRange, getSelectionRange } from '../Editor.utils';

const fuseOptions = {
  shouldSort: true,
  includeScore: true,
  findAllMatches: false,
  minMatchCharLength: 2,
  threshold: 0.25,
};

export const getMatch = () => {
  const range = getSelectionRange();

  let text =
    range && range.startContainer.textContent.substring(0, range.startOffset);

  if (!text || /\s+$/.test(text)) return null;

  const start = text.lastIndexOf(' ') + 1;
  const end = range.startOffset;

  return {
    end,
    start,
    text: text.substring(start),
  };
};

export const getTranslationMatch = () => {
  const range = getSelectionRange();
  if (!range) return null;

  let text = range && range.endContainer.querySelector('textarea').value;

  if (!text || /\s+$/.test(text)) return null;

  const start = text.lastIndexOf(' ') + 1;
  const end = range.startOffset;

  return {
    end,
    start,
    text: text.substring(start),
  };
};

export const isFirstLetterCapitalized = (string) => {
  return string.charAt(0) === string.charAt(0).toUpperCase();
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const removeDiacritics = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

/**
 * Get suggestions from autocomplete
 *
 * @param {Array} autocomplete
 * @param {{}} match
 * @param {Number} limit
 * @returns {Array} suggestions
 */
export const getSuggestions = (autocomplete, text, limit = 2) => {
  // return null if text :
  // - null or empty
  // - not a string
  // - under 3 characters
  if (!text || typeof text !== 'string' || text.length < 2) return null;

  const filter = (item) => {
    let query = item.normalizedValue.substring(0, 2);
    return query === term.substring(0, 2);
  };

  // define the list used for search with the original and normalized suggestion value
  // (i.e. remove diacritics (accents) and case-insensitive)
  const list = autocomplete.map((suggestion) => ({
    value: suggestion,
    normalizedValue: removeDiacritics(suggestion).toLowerCase(),
  }));

  const options = {
    ...fuseOptions,
    keys: ['value', 'normalizedValue'],
  };

  // normalize search term
  const term = removeDiacritics(text).toLowerCase();

  // apply a fuzzy search in order to have relevant suggestions from a search term
  const fuse = new Fuse(list, options);

  const searchResults = fuse
    .search(term)
    .map((f) => f.item)
    .filter(filter);

  const suggestions = searchResults.map((s) => s.value);

  // Remove if matching the whole term
  if (suggestions.map((s) => s.toLowerCase()).includes(text.toLowerCase()))
    suggestions.splice(
      suggestions.map((s) => s.toLowerCase()).indexOf(text.toLowerCase()),
      1
    );

  return limit ? suggestions.slice(0, limit) : suggestions;
};

export const normalizeIndex = (selectedIndex, max) => {
  let index = selectedIndex % max;
  if (index < 0) {
    index += max;
  }

  return index;
};

export const addSuggestion = (editorState, item) => {
  const { start, end } = getInsertRange(editorState);
  const contentState = editorState.getCurrentContent();
  const currentSelection = editorState.getSelection();

  const selection = currentSelection.merge({
    anchorOffset: start,
    focusOffset: end,
  });

  const contentStateWithEntity = contentState.createEntity(
    'SUGGESTION',
    'MUTABLE',
    {
      item,
    }
  );

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newContentState = Modifier.replaceText(
    contentStateWithEntity,
    selection,
    `${item} `,
    null,
    entityKey
  );

  const newEditorState = EditorState.push(
    editorState,
    newContentState,
    'insert-suggestion'
  );

  return EditorState.forceSelection(
    newEditorState,
    newContentState.getSelectionAfter()
  );
};

export const findEntities = (suggestions, contentBlock, callback) => {
  const text = contentBlock.getText();

  suggestions.forEach((s) => {
    let matchArr, start;
    // FIXME: When used, adjust regexp here
    // to match exact word or expression with ANY characters
    const regex = new RegExp(`\\b${s}\\b`, 'gi');
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  });
};

export const getAutocompleteStrategy =
  (suggestions) => (contentBlock, callback, contentState) => {
    findEntities(suggestions, contentBlock, callback);
  };
