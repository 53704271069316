import styled from 'styled-components';
import { greaterThan, lessThan } from '../../../utils/mediaQuery';

export const Section = styled.section`
  position: relative;

  /* width: 100%;
  max-width: 1448px;
  margin: 0 auto;
  padding-top: 1rem; */
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  z-index: 1;
  justify-content: flex-start;
  overflow: hidden;

  ${greaterThan(1440)} {
    min-width: 892px;
  }

  ::before,
  ::after {
    position: absolute;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 30px;
    pointer-events: none;
    content: '';
  }

  ::before {
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0),
      var(--color-light-gray) 90%
    );
  }

  ::after {
    bottom: 0;

    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      var(--color-light-gray) 90%
    );
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  .ReactVirtualized__Grid__innerScrollContainer {
    margin: 2px;
    box-shadow: 0 1px 2px var(--shadow-color);
  }

  ${lessThan(1440)} {
    max-width: var(--max-content-width);
    margin: 0 auto;
  }
  /* margin-top: 1rem; */
`;
