import { css, createGlobalStyle } from 'styled-components';
import 'styled-components/macro';

import colorsStyle from './colors';

export const rootStyle = css`
  ${colorsStyle}

  --text-color: var(--color-charcoal);
  --primary-color: hsl(var(--color-green-h), var(--color-green-l), var(--color-green-s));
  --background-color: var(--color-light-gray);
  --button-background-color: var(---primary-color);

  --shadow-color: rgba(0, 0, 0, 0.2);

  /* --button-border-radius: 6px; */
  /* --button-text-color: white;
  --button-height: 50px; */

  --box-shadow-1: 0px 0.8px 3.2px rgb(0 0 0 / 9%), 0px 1.9px 8.1px rgb(0 0 0 / 5%),
    0px 3.6px 16.5px rgb(0 0 0 / 4%), 0px 7.2px 33.9px rgb(0 0 0 / 3%),
    0px 20px 93px rgb(0 0 0 / 3%);

  --header-height: 75px;
  --font-size: 0.875rem;
  /* --font-size: 1rem; */

  --max-content-width: 1440px;
`;

export const htmlStyle = css`
  height: 100vh;
  scroll-behavior: smooth;
  /* overflow-y: hidden; */
`;

export const bodyStyle = css`
  height: 100%;
  margin: 0;

  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  font-size: var(--font-size);
  /* letter-spacing: 0.25px; */

  color: var(--text-color);
  background: var(--background-color);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const buttonStyle = css`
  .bp3-button.bp3-intent-primary {
    --color-green-s: 64%;

    background-color: hsl(
      var(--color-green-h),
      var(--color-green-s),
      var(--color-green-l)
    );
    background-image: linear-gradient(
      45deg,
      hsl(var(--color-green-h), var(--color-green-s), 30%) 0%,
      hsl(var(--color-green-h), var(--color-green-s), 43%) 70%,
      hsl(var(--color-green-h), var(--color-green-s), var(--color-green-l)) 100%
    );

    border: 1px solid hsl(var(--color-green-h), var(--color-green-s), 30%);
    box-shadow: none;
  }
  .bp3-button.bp3-intent-primary:hover,
  .bp3-button.bp3-intent-primary:focus {
    background-color: #187756;
    background-image: linear-gradient(45deg, #187756 0%, #24aa5d 100%);
    border-color: #187756;
    box-shadow: none;
  }
  .bp3-button.bp3-intent-primary:active,
  .bp3-button.bp3-intent-primary.bp3-active {
    background-color: #187756;
    background-image: none;
    box-shadow: inset 0 0 0 1px #187756, inset 0 1px 2px #14664a;
  }
  .bp3-button.bp3-intent-primary:disabled,
  .bp3-button.bp3-intent-primary.bp3-disabled {
    background-color: #14664a;
    background-image: linear-gradient(45deg, #14664a 0%, #209754 100%);
    border-color: #14664a;
    box-shadow: none;
  }
  .bp3-button.bp3-large,
  .bp3-large .bp3-button {
    font-weight: 600;
  }
  .bp3-spinner .bp3-spinner-track {
    stroke: rgba(255, 255, 255, 0.2);
  }
  .bp3-toast-message {
    line-height: 1.4;
  }
`;

export const dialogStyle = css`
  .bp3-dialog-header {
    --color-green-s: 64%;

    padding: 16px 14px 10px 24px;

    background-color: hsl(
      var(--color-green-h),
      var(--color-green-s),
      var(--color-green-l)
    );
    background-image: linear-gradient(
      90deg,
      hsl(var(--color-green-h), var(--color-green-s), 33%) 0%,
      hsl(var(--color-green-h), var(--color-green-s), var(--color-green-l)) 100%
    );
    /* background: #1e996e;
    background-image: linear-gradient(90deg, #1e996e 0%, #53dc41 100%); */
  }

  .bp3-dialog-header {
    > * {
      color: var(--color-white);
    }
    .bp3-heading {
      font-weight: 700;
    }
    .bp3-icon {
      color: var(--color-white);
    }
  }
`;

export const portalStyle = css`
  .bp3-portal .bp3-menu-item.bp3-intent-primary.bp3-active {
    --color-green-s: 64%;

    background-color: hsl(
      var(--color-green-h),
      var(--color-green-s),
      var(--color-green-l)
    );
    background-image: linear-gradient(
      90deg,
      hsl(var(--color-green-h), var(--color-green-s), 30%) 0%,
      hsl(var(--color-green-h), var(--color-green-s), var(--color-green-l)) 100%
    );
  }
`;

export const popoverStyle = css`
  .bp3-popover2 {
    box-shadow: var(--box-shadow-1);
  }

  .editor-popover .bp3-popover2,
  .editor-popover .bp3-popover2 .bp3-popover2-content {
    border-radius: 6px;
  }
`;

export const spinnerStyle = css`
  .bp3-spinner .bp3-spinner-track {
    stroke: hsl(var(--color-green-h), var(--color-green-s), 80%);
  }

  .bp3-spinner .bp3-spinner-head {
    stroke: hsl(var(--color-green-h), var(--color-green-s), var(--color-green-l));
  }
`;

export const switchStyle = css`
  .bp3-control.bp3-switch {
    display: inline-block;
    margin: 0;

    & input:checked ~ .bp3-control-indicator {
      background: #27c28c;
      background-image: linear-gradient(90deg, #27c28c 0%, #8ce880 100%);
    }

    &:hover input:checked ~ .bp3-control-indicator {
      background: #1e996e;
      background-image: linear-gradient(90deg, #1e996e 0%, #53dc41 100%);
    }
  }
`;

export const hideVisuallyStyle = css`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

  clip: rect(0 0 0 0);
  overflow: hidden;
  white-space: nowrap;
`;

export const unstyledList = css`
  list-style: none;
  padding: 0;
`;

export const GlobalStyle = createGlobalStyle`
  :root {
    ${rootStyle}
  }

  html {
    ${htmlStyle}
  }

  body {
    ${bodyStyle}
  }

  #root {
    height: 100%;
  }

  ${buttonStyle}

  ${dialogStyle}

  ${popoverStyle}

  ${spinnerStyle}

  ${switchStyle}

  .no-backdrop {
    background: none;
  }

  input:focus {
    outline: none;
  }

  ::selection {
    background: hsl(
      var(--color-green-h),
      var(--color-green-s),
      83%
    ); /* WebKit/Blink Browsers */
  }
  ::-moz-selection {
    background: hsl(
      var(--color-green-h),
      var(--color-green-s),
      83%
    ); /* Gecko Browsers */
  }
`;
