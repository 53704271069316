// vendors
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Routes,
  Route,
  useSearchParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';

// actions
import {
  editData,
  saveData,
  setCurrentStep,
  setIsNextStep,
  updateTimeStamp,
} from '../states/Actions';

// reducers
import { step } from '../states/Reducers/app';

// components
import Layout from '../components/Layout';
import {
  useNotifications,
  withNotificationsProvider,
} from '../components/Notifications/Notifications.context';
import { useVideoPlayer, withVideoPlayerProvider } from '../components/VideoPlayer';

// views
import TranscriptionView from '../views/TranscriptionView';
import TranslationView from '../views/TranslationView';
import CorrectionView from '../views/CorrectionView';

const App = () => {
  const [autoSave, setAutoSave] = useState(true);

  const saveIntervalRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const { dispatch: notificationsDispatcher } = useNotifications();

  const { seekToSeconds, duration, progress } = useVideoPlayer();

  const videoTimeStamp = useSelector((state) => state.app.data.videotimestamp);
  const initialStep = useSelector((state) => state.app.initialStep);
  const currentStep = useSelector((state) => state.app.data.step);

  const transcriptionStatus = useSelector((state) => state.transcription.status);
  const correctionStatus = useSelector((state) => state.correction.status);
  const transcriptionFinished = useSelector(
    (state) => state.transcription.data.finished
  );
  const transcriptionEditeurURL = useSelector(
    (state) => state.transcription.data.editeururl
  );
  const transcriptionContent = useSelector(
    (state) => state.transcription.data.content
  );
  const transcriptionTitle = useSelector((state) => state.transcription.data.title);
  const editTranscriptionSinceLastSaved = useSelector(
    (state) => state.transcription.data.editSinceLastSaved
  );

  const translationStatus = useSelector((state) => state.translation.status);
  const translationFinished = useSelector(
    (state) => state.translation.data.finished
  );
  const translationEditeurURL = useSelector(
    (state) => state.translation.data.editeururl
  );
  const translationContent = useSelector((state) => state.translation.data.content);
  const translationChildren = useSelector(
    (state) => state.translation.data.children
  );
  const translationTitle = useSelector((state) => state.translation.data.title);
  const editTranslationSinceLastSaved = useSelector(
    (state) => state.translation.data.editSinceLastSaved
  );

  const isCorrection = location?.pathname?.includes(step.CORRECTION);
  const isTranslation = location.pathname.includes(step.TRANSLATION);
  const pathnames = location?.pathname?.split('/').filter((p) => p);
  const id = isTranslation ? pathnames[1] : pathnames[0];
  const token = searchParams.get('token');

  const finished = useMemo(() => {
    if (isTranslation) return translationFinished;
    return transcriptionFinished;
  }, [isTranslation, translationFinished, transcriptionFinished]);

  const succeeded = useMemo(() => {
    if (isTranslation)
      return translationStatus === 'succeeded' || correctionStatus === 'succeeded';
    return transcriptionStatus === 'succeeded' || correctionStatus === 'succeeded';
  }, [correctionStatus, isTranslation, transcriptionStatus, translationStatus]);

  const editSinceLastSaved = useMemo(() => {
    if (isTranslation) return editTranslationSinceLastSaved;
    return editTranscriptionSinceLastSaved;
  }, [
    isTranslation,
    editTranslationSinceLastSaved,
    editTranscriptionSinceLastSaved,
  ]);

  const editeurURL = useMemo(() => {
    if (isTranslation) return translationEditeurURL;
    return transcriptionEditeurURL;
  }, [isTranslation, transcriptionEditeurURL, translationEditeurURL]);

  const content = useMemo(() => {
    if (isTranslation) return translationContent;
    return transcriptionContent;
  }, [isTranslation, transcriptionContent, translationContent]);

  const title = useMemo(() => {
    if (isTranslation) return translationTitle;
    return transcriptionTitle;
  }, [isTranslation, transcriptionTitle, translationTitle]);

  const handleSave = useCallback(() => {
    dispatch(
      saveData(notificationsDispatcher, navigate, {
        id,
        token,
        isTranslation,
        isCorrection,
      })
    );
  }, [
    dispatch,
    id,
    isCorrection,
    isTranslation,
    navigate,
    notificationsDispatcher,
    token,
  ]);

  const handleNextStep = useCallback(() => {
    let nextStep = {};

    switch (currentStep?.name) {
      case step.TRANSLATION:
        nextStep = {
          name: step.CORRECTION,
          path: `/translation/${id}/correction?token=${token}`,
        };
        break;
      case step.TRANSCRIPTION:
        nextStep = {
          name: step.CORRECTION,
          path: `/${id}/correction?token=${token}`,
        };
        break;
      case step.CORRECTION:
        dispatch(editData({ finished: true, isTranslation }));
        break;
      default:
        break;
    }

    dispatch(setCurrentStep({ ...nextStep }));
    dispatch(setIsNextStep(true));

    handleSave();
  }, [currentStep?.name, dispatch, handleSave, id, token, isTranslation]);

  const handleClickAutoSave = useCallback(() => {
    setAutoSave((prev) => !prev);
  }, []);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.ctrlKey && e.code === 'KeyS' && !e.altKey && !e.shiftKey && !e.metaKey) {
        e.preventDefault();

        handleSave();
      }
    },
    [handleSave]
  );

  // initialize event listeners
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  // update last video progress position
  useEffect(() => {
    if (!progress || !progress.playedSeconds) return;

    dispatch(updateTimeStamp(progress.playedSeconds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  // seek video time from shared url with time parameter if exist
  // or last saved video progress position
  useEffect(() => {
    if (!duration) return;

    if (window.location.href.includes('&time=')) {
      const splittedUrl = window.location.href.split('&time=');
      const time = Number(splittedUrl[1]);

      seekToSeconds(time);
      return;
    }

    if (!videoTimeStamp) return;

    seekToSeconds(videoTimeStamp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  // save content 30 secs after the last edit
  useEffect(() => {
    if (!succeeded) return;

    if (autoSave && editSinceLastSaved && content) {
      saveIntervalRef.current = setTimeout(() => handleSave(), 3 * 10000);
    }

    if (saveIntervalRef.current && !autoSave) {
      clearTimeout(saveIntervalRef.current);
    }

    return () => {
      clearTimeout(saveIntervalRef.current);
    };
  }, [
    handleSave,
    autoSave,
    editSinceLastSaved,
    content,
    succeeded,
    translationChildren,
  ]);

  // redirect to Editeur if finished after 3 secs
  useEffect(() => {
    if (!editeurURL) return;

    setTimeout(() => {
      window.location.href = editeurURL;
    }, 3000);
  }, [editeurURL]);

  return (
    <Layout
      title={title}
      autoSave={autoSave}
      onAutoSave={handleClickAutoSave}
      onSave={handleSave}
      onNextStep={handleNextStep}
      lastStepLoading={finished && editeurURL}
      initialStep={initialStep}
    >
      <Routes>
        <Route path="/">
          <Route path=":id" element={<TranscriptionView />} />
          <Route path=":id/correction" element={<CorrectionView />} />
          <Route path="translation/:id" element={<TranslationView />} />
          <Route path="translation/:id/correction" element={<CorrectionView />} />
        </Route>
      </Routes>
    </Layout>
  );
};

export default withNotificationsProvider(withVideoPlayerProvider(App));
