// vendors
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNotifications } from '../../components/Notifications/Notifications.context';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Switch } from '@blueprintjs/core';
import 'styled-components/macro';

// states
import {
  fetchTranslationData,
  fetchOriginalData,
  setCurrentStep,
  // setInitialStep,
  setTranslationFetched,
} from '../../states/Actions';
import { step } from '../../states/Reducers/app';

// components
import Shortcuts from '../../components/Shortcuts';
import VideoPlayer from '../../components/VideoPlayer';
import { SearchSentence } from '../../components/SearchSentence/SearchSentence';

// views
import LoadingView from '../LoadingView';
import EditorSection from './EditorSection';

// utils
import { between } from '../../utils/mediaQuery';

// styles
import {
  Container,
  PlayerSection,
  PlayerWrapper,
  ToolbarContainer,
  ToolbarGroup,
} from './TranslationView.styles';
import { noAspectRatioStyle } from '../../components/VideoPlayer/VideoPlayer.styles';

const TranslationView = () => {
  const [pauseOnTyping, setPauseOnTyping] = useState(false);
  const { dispatch: notificationsDispatcher } = useNotifications();

  const dispatch = useDispatch();

  const status = useSelector((state) => state.translation.status);
  const originalDataStatus = useSelector(
    (state) => state.translation.originalDataStatus
  );
  const originalData = useSelector((state) => state.translation.data.original);

  const isFetched = useSelector((state) => state.translation.isFetched);

  const videoSrc = useSelector((state) => state.translation.data.videolink);
  const videoExists = useSelector((state) => state.translation.data.videoExists);
  const audioSrc = useSelector((state) => state.translation.data.audiofile);

  const finalUrl = videoExists ? videoSrc : audioSrc;

  const handleClickPauseOnTyping = useCallback(
    () => setPauseOnTyping((prev) => !prev),
    []
  );

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const id = location?.pathname?.split('/').filter((p) => p)[1];
  const token = searchParams.get('token');

  // fetch the original data from the api **only once**
  useEffect(() => {
    // set transcription as default step
    dispatch(
      setCurrentStep({
        name: step.TRANSLATION,
        path: `/translation/${id}?token=${token}`,
      })
    );

    // get original data from the api
    dispatch(fetchOriginalData(notificationsDispatcher, { id, token }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch translation data from the api only if the original content data exists
  useEffect(() => {
    if (!originalData.content) return;

    // dispatch(setInitialStep(step.TRANSLATION));

    // set transcription as default step
    dispatch(
      setCurrentStep({
        name: step.TRANSLATION,
        path: `/translation/${id}?token=${token}`,
      })
    );

    dispatch(
      fetchTranslationData(notificationsDispatcher, {
        id,
        token,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalData.content]);

  useEffect(() => {
    if (!isFetched) return;

    dispatch(setTranslationFetched(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]);

  const isLoading = useMemo(
    () => status === 'loading' || originalDataStatus === 'loading',
    [originalDataStatus, status]
  );

  if (isLoading) return <LoadingView />;

  return (
    <Container>
      <EditorSection pauseOnTyping={pauseOnTyping} />

      <PlayerSection>
        <PlayerWrapper>
          <VideoPlayer
            src={finalUrl}
            videoExists={videoExists}
            css={`
              ${between(940, 1440)} {
                ${noAspectRatioStyle}
              }
            `}
          />

          <ToolbarContainer>
            <ToolbarGroup>
              <Switch
                label="Pause while typing"
                checked={pauseOnTyping}
                onChange={handleClickPauseOnTyping}
              />
            </ToolbarGroup>
            <div>
              <SearchSentence />
            </div>
            <div>
              <Shortcuts />
            </div>
          </ToolbarContainer>
        </PlayerWrapper>
      </PlayerSection>
    </Container>
  );
};

export default TranslationView;
