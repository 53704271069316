// vendors
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import 'styled-components/macro';

// states
import {
  editTranslationList,
  // HANDLE_BUTTON_CLICKS,
} from '../../../../states/Actions';

// contexts
import {
  ADD_NOTIFICATION,
  useNotifications,
} from '../../../../components/Notifications/Notifications.context';

// components
import { useVideoPlayer } from '../../../../components/VideoPlayer';
import SpeechToText from '../../../../components/SpeechToText';
import Suggestions from '../../../../components/Editor/Suggestions';

// styles
import { TextArea } from './TextEditor.styles';

// utils
import { getTranslationSelectionPosition } from '../../../../components/Editor/Editor.utils';
import { getSuggestions, getTranslationMatch } from '../../../../components/Editor/Suggestions/Suggestions.utils';

export const handlePastedText = (notificationsDispatcher, accessMode) => (e) => {
  const MAX_CHAR = 15;

  const clipboardData = e.clipboardData || window.clipboardData;

  const text = clipboardData.getData('Text');

  if (text.length <= MAX_CHAR || (accessMode && accessMode === "PROOFREADER")) return true;

  // Stop data being pasted into textarea
  e.stopPropagation();
  e.preventDefault();

  notificationsDispatcher({
    type: ADD_NOTIFICATION,
    payload: {
      message: {
        title: `Maximum of 15 characters allowed reached (${
          text.length - MAX_CHAR
        } found )`,
        content: `Reduce the number of characters to paste into the text box and try again.`,
      },
      timeout: 10000,
      intent: 'warning',
      icon: 'warning-sign',
    },
  });

  return false;
};

const TextEditor = ({
  index,
  text,
  lexical,
  readOnly,
  pauseOnTyping,
  onClick,
  evalChildren,
  onKeyDown,
  focused,
  focusToEnd,
  ...rest
}) => {
  const dispatch = useDispatch();
  const editorRef = useRef();
  const { dispatch: notificationsDispatcher } = useNotifications();
  const { isPlaying, setIsPlaying } = useVideoPlayer();
  const accessMode = useSelector((state) => state.translation.data.accessmode);
  const [autocompleteState, setAutocompleteState] = useState({
    match: null,
    selectedSuggestion: 0,
  });

  const updateValue = useCallback(
    (newValue) => {
      if (newValue === text) return;

      if (pauseOnTyping && isPlaying) setIsPlaying(false);

      dispatch(editTranslationList({ content: newValue, index }));
    },
    [dispatch, index, isPlaying, pauseOnTyping, setIsPlaying, text]
  );

  const emitClick = useCallback(
    (index) => {
      if (typeof onClick === 'function') onClick(index);
    },
    [onClick]
  );

  const handleClick = useCallback(() => {
    emitClick(index);

    // editorRef.current?.focus();
  }, [emitClick, index]);

  const moveFocusToBeginning = useCallback(() => {
    editorRef.current.setSelectionRange(0, 0);
    editorRef.current.focus();
  }, []);

  const moveFocusToEnd = useCallback(() => {
    const end = editorRef.current.value.length;

    editorRef.current.setSelectionRange(end, end);
    editorRef.current.focus();
  }, []);

  // Update focus:
  // - when focused is true;
  // - Move focus to the end of the text when focusToEnd is true or to the beginning of the text otherwise.
  useEffect(() => {
    if (!focused) return;

    if (focusToEnd) {
      moveFocusToEnd();
      return;
    }

    moveFocusToBeginning();
  }, [focusToEnd, focused]);

  //Suggestions
  const resetAutocompleteState = useCallback(() => {
    setAutocompleteState({
      match: null,
      selectedSuggestion: 0,
    });
  }, [setAutocompleteState]);

  const emitKeyDown = useCallback(
    (e) => {
      if (typeof onKeyDown === 'function') onKeyDown(e);
      if (e.key === "Tab") resetAutocompleteState();
    },
    [onKeyDown, resetAutocompleteState]
  );

  const handleSuggestionSelected = (item) => {
    if(!item || item.length === 0 || text.length === 0) return;
    const currentTextArray = text.split(' ');

    if (currentTextArray.length === 0) return;
    const newText = [...currentTextArray.slice(0, -1), item].join(' ');

    updateValue(newText);

    resetAutocompleteState();
  };

  const renderSuggestions = () => {
    const { match, selectedSuggestion } = autocompleteState;

    if (!match) return null;

    const { suggestions } = match;

    if (!suggestions || suggestions.length === 0) return null;

    return (
      <Suggestions
        {...match}
        selectedSuggestion={selectedSuggestion}
        onSelect={(val) => handleSuggestionSelected(val)}
        onClose={() => resetAutocompleteState()}
      />
    );
  };

  const updateAutocompleteState = useCallback((newValue) => {
    // Reset if text is empty
    if (!newValue || newValue === "") return resetAutocompleteState();

    // Reset if no match found
    const match = getTranslationMatch();

    if (!match) return resetAutocompleteState();

    // Reset if no suggestions found
    const suggestions = getSuggestions(lexical, match?.text);
  
    if (!suggestions || suggestions.length === 0) return resetAutocompleteState();

    // Update position
    const position = getTranslationSelectionPosition();

    const newMatch = {
      ...match,
      suggestions,
      position,
    };

    // Update selectedSuggestion if too high
    let { selectedSuggestion } = autocompleteState;
    const lastSuggestionIndex = suggestions.length > 0 ? suggestions.length - 1 : 0;
    if (selectedSuggestion > lastSuggestionIndex) {
      selectedSuggestion = lastSuggestionIndex;
    }

    setAutocompleteState({
      match: newMatch,
      selectedSuggestion,
    });
  }, [resetAutocompleteState, autocompleteState, lexical, setAutocompleteState]);

  const handleChange = useCallback(
    (e) => {
      const newText = e.target.value;

      updateValue(newText);

      if (lexical.length > 0) {
        updateAutocompleteState(newText);
      }
    },
    [updateValue, updateAutocompleteState, lexical]
  );

  return (
    <div onClick={handleClick} onFocus={handleClick} {...rest}>
      <div
        css={`
          --speech-text-height: 36px;
          height: 100%;
        `}
      >
        {/* NOTE: Initialize SpeechToText on focus ONLY in order to avoid rendering performance issues
            specially when used multiple times in a long list of items
        */}
        {focused && (
          <SpeechToText
            initialValue={text}
            onChange={updateValue}
            onRecording={moveFocusToEnd}
            css={`
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: var(--speech-text-height);
              display: flex;
              justify-content: flex-end;
              align-items: baseline;

              background: var(--color-white);
              padding: 8px 0.5rem 4px 1rem;
            `}
          />
        )}

        <TextArea
          lexical={lexical}
          ref={editorRef}
          value={text}
          onChange={handleChange}
          onKeyDown={emitKeyDown}
          onPaste={handlePastedText(notificationsDispatcher, accessMode)}
          // onClick={handleClick}
          css={`
            margin-top: ${focused ? `calc(var(--speech-text-height) - 8px)` : `0`};
            height: calc(100% - var(--speech-text-height) + 18px);
            padding-right: 1rem;
          `}
        />
        {renderSuggestions()}
      </div>
    </div>
  );
};

TextEditor.propTypes = {
  index: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  pauseOnTyping: PropTypes.bool,
  focused: PropTypes.bool,
  focusToEnd: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  lexical: PropTypes.arrayOf(PropTypes.string),
};
TextEditor.defaultProps = {
  pauseOnTyping: false,
  focused: false,
  focusToEnd: false,
  onClick: () => {},
  onKeyDown: undefined,
  lexical: [],
};

export default React.memo(TextEditor);
