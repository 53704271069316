import styled from 'styled-components';

export const DraftEditorContainer = styled.div`
  min-height: 100px;
  width: 100%;
  overflow-y: auto;
`;

export const TextArea = styled.textarea`
  /* height: 100%; */
  width: 100%;
  padding: 0 4px 4px;

  resize: none;
  border: none;
  border-radius: inherit;

  line-height: 1.7;
  letter-spacing: 0.35px;

  background-color: transparent;
  outline: none;
  overflow: hidden auto;
`;
