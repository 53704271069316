import React, { useContext, useState } from 'react';

import { EditorState } from 'draft-js';

export const EditorContext = React.createContext();

export const useEditor = () => useContext(EditorContext);

export const EditorProvider = ({ children }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isErrorCorrectionPopoverOpened, setIsErrorCorrectionPopoverOpened] =
    useState(false);

  return (
    <EditorContext.Provider
      value={{
        editorState,
        setEditorState,
        isErrorCorrectionPopoverOpened,
        setIsErrorCorrectionPopoverOpened,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

export const withEditorProvider = (WrapperComponent) => (props) =>
  (
    <EditorProvider>
      <WrapperComponent {...props} />
    </EditorProvider>
  );
