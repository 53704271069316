// vendors
import React, { useRef } from 'react';
import { Classes, Popover2 as Popover } from '@blueprintjs/popover2';
import 'styled-components/macro';

// components
import ErrorHighlight from './ErrorHighlight';

// context
import { useEditor } from '../Editor.context';

// utils
import {
  addCorrection,
  forceSelection,
  getColorFromRuleCategoryId,
  getRuleLabelFromId,
  removeCorrection,
} from './ErrorCorrection.utils';

// styles
import {
  Container,
  Content,
  CloseButton,
  Description,
  Suggestions,
  Suggestion,
  Headline,
  Tag,
  Wrapper,
  Menu,
  MenuDivider,
  MenuItem,
} from './ErrorCorrection.styles';

const MAX_SUGGESTIONS = 5;

const ErrorCorrection = ({
  blockKey,
  entityKey,
  contentState,
  children,
  start,
  end,
  popoverClick,
}) => {
  // const id = `popover-error-correction-${blockKey}`;
  const popoverRef = useRef(null);
  const { editorState, setEditorState, setIsErrorCorrectionPopoverOpened } =
    useEditor();

  const {
    message,
    replacements: suggestions,
    rulecatid,
  } = contentState.getEntity(entityKey).getData();

  const color = getColorFromRuleCategoryId(rulecatid);

  const theme = {
    red: 'var(--color-red-hsl)',
    yellow: 'var(--color-yellow-hsl)',
    blue: 'var(--color-blue-hsl)',
  };

  const handleOpenPopover = () => {
    popoverClick();
    setIsErrorCorrectionPopoverOpened(true);
  };

  const handleClosePopover = () => {
    setIsErrorCorrectionPopoverOpened(false);
  };

  const handleClickSuggestion = (selected) => {
    const updatedEditorState = addCorrection(editorState, selected, {
      start,
      end,
      blockKey,
      entityKey,
    });

    setEditorState(updatedEditorState);

    handleClosePopover();

    // setEditorState(EditorState.set(editorState, { currentContent: foo }));
  };

  const handleIgnoreError = () => {
    const updatedEditorState = removeCorrection(editorState, {
      start,
      end,
      blockKey,
    });

    setEditorState(updatedEditorState);

    handleClosePopover();
  };

  const handleClose = () => {
    // force to the current cursor/selection state of the editor
    const newEditorState = forceSelection(
      editorState,
      editorState.getSelection()
    );

    setEditorState(newEditorState);
  };

  const renderContent = () => (
    <Container>
      <Wrapper>
        <Content>
          <Headline theme={theme[color]}>
            <span>{getRuleLabelFromId(rulecatid)}</span>
          </Headline>

          <Description>{message}</Description>

          <Suggestions>
            {suggestions
              .filter((_, index) => index < MAX_SUGGESTIONS)
              .map((suggestion, index) => (
                <Suggestion key={`suggestion-${index}`}>
                  <Tag
                    large
                    interactive
                    onClick={() => handleClickSuggestion(suggestion)}
                    className={Classes.POPOVER2_DISMISS}
                  >
                    {suggestion}
                  </Tag>
                </Suggestion>
              ))}
          </Suggestions>
        </Content>

        <Menu>
          <MenuDivider />
          <MenuItem
            icon="remove"
            text="Ignorer dans ce texte"
            onClick={handleIgnoreError}
          />
        </Menu>

        <CloseButton
          className={Classes.POPOVER2_DISMISS}
          icon="small-cross"
          minimal
          onClick={handleClose}
        />
      </Wrapper>
    </Container>
  );

  const renderTarget = ({ isOpen, ref, ...targetProps }) => {
    return (
      <ErrorHighlight
        elementRef={ref}
        active={isOpen}
        theme={theme[color]}
        {...targetProps}
      >
        {children}
      </ErrorHighlight>
    );
  };

  return (
    <Popover
      minimal
      usePortal
      // disabled={hideErrorCorrection}
      // fill
      popoverRef={popoverRef}
      canEscapeKeyClose
      autoFocus={false}
      // openOnTargetFocus
      // enforceFocus={true}
      hasBackdrop={false}
      shouldReturnFocusOnClose
      interactionKind="click"
      placement="bottom-start"
      portalClassName="editor-popover"
      content={renderContent()}
      renderTarget={(props) => renderTarget(props)}
      onOpened={handleOpenPopover}
      onClosing={handleClosePopover}
    />
  );
};

ErrorCorrection.propTypes = {};

export default ErrorCorrection;
