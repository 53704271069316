import {
  editTranscription,
  fetchTranscriptionData,
  saveTranscriptionData,
} from './transcription';
import {
  editTranslation,
  fetchTranslationData,
  saveTranslationData,
} from './translation';

export const SET_IS_CORRECTION_STEP = 'SET_IS_CORRECTION_STEP';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_INITIAL_STEP = 'SET_INITIAL_STEP';
export const SET_IS_NEXT_STEP = 'SET_IS_NEXT_STEP';
export const UPDATE_TIME_STAMP = 'UPDATE_TIME_STAMP';

export const fetchData = (notificationsDispatcher, params) => (dispatch) => {
  const { isTranslation } = params;

  if (isTranslation) {
    dispatch(fetchTranslationData(notificationsDispatcher, params));
    return;
  }

  dispatch(fetchTranscriptionData(notificationsDispatcher, params));
};

export const saveData =
  (notificationsDispatcher, navigate, params) => (dispatch) => {
    const { isTranslation } = params;

    if (isTranslation) {
      dispatch(saveTranslationData(notificationsDispatcher, navigate, params));
      return;
    }

    dispatch(saveTranscriptionData(notificationsDispatcher, navigate, params));
  };

export const editData = (params) => (dispatch) => {
  const { isTranslation } = params;

  if (isTranslation) {
    dispatch(editTranslation({ ...params }));
    return;
  }

  dispatch(editTranscription({ ...params }));
};

export const setCurrentStep = (step) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_STEP,
    payload: {
      step,
    },
  });
};

export const setInitialStep = (initialStep) => (dispatch) => {
  dispatch({
    type: SET_INITIAL_STEP,
    payload: {
      initialStep,
    },
  });
};

export const setIsNextStep = (isNextStep) => (dispatch) => {
  dispatch({
    type: SET_IS_NEXT_STEP,
    payload: {
      isNextStep,
    },
  });
};

export const updateTimeStamp = (time) => (dispatch) => {
  dispatch({
    type: UPDATE_TIME_STAMP,
    payload: {
      data: { videotimestamp: time },
    },
  });
};
