// vendors
import React from 'react';
import 'styled-components/macro';

// components
import ButtonWithDialog from '../ButtonWithDialog';

// styles
import {
  noPaddingTopStyle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableRowLabel,
} from './Shortcuts.styles';

// TODO: Handle shortcuts dynamically
const Shortcuts = () => (
  <ButtonWithDialog
    icon="key-command"
    buttonText="Keyboard shortcuts"
    title="Keyboard shortcuts"
  >
    <Table>
      <TableBody>
        <TableRowLabel>
          <TableHead colSpan={2} css={noPaddingTopStyle}>
            GLOBAL
          </TableHead>
        </TableRowLabel>
        <TableRow>
          <TableHead>Save project</TableHead>
          <TableCell>Ctrl + s</TableCell>
        </TableRow>
        <TableRow>
          <TableHead>Pause/play</TableHead>
          <TableCell>Shift + Espace</TableCell>
        </TableRow>
        <TableRow>
          <TableHead>Go back 5 seconds</TableHead>
          <TableCell>Shift + &larr;</TableCell>
        </TableRow>
        <TableRow>
          <TableHead>Move forward 5 seconds</TableHead>
          <td>Shift + &rarr;</td>
        </TableRow>
        <TableRow>
          <TableHead>Reduce playback speed</TableHead>
          <TableCell>Shift + &darr;</TableCell>
        </TableRow>
        <TableRow>
          <TableHead>Increase reading speed</TableHead>
          <TableCell>Shift + &uarr;</TableCell>
        </TableRow>
      </TableBody>
      <TableBody>
        <TableRowLabel>
          <TableHead colSpan={2}>
            When the auto-complete bubble is displayed
          </TableHead>
        </TableRowLabel>
        <TableRow>
          <TableHead>
            Move selection to next suggestion
          </TableHead>
          <TableCell>Tab</TableCell>
        </TableRow>
        <TableRow>
          <TableHead>Select a suggestion</TableHead>
          <TableCell>Enter</TableCell>
        </TableRow>
        <TableRow>
          <TableHead>Close autofill bubble</TableHead>
          <TableCell>Esc</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </ButtonWithDialog>
);

export default Shortcuts;
