import { ADD_NOTIFICATION } from '../../components/Notifications/Notifications.context';
import { setIsNextStep, UPDATE_TIME_STAMP } from './app';
import Config from '../../libs/Config';

export const FETCH_TRANSCRIPT_DATA_BEGIN = 'FETCH_TRANSCRIPT_DATA_BEGIN';
export const FETCH_TRANSCRIPT_DATA_SUCCESS = 'FETCH_TRANSCRIPT_DATA_SUCCESS';
export const FETCH_TRANSCRIPT_DATA_FAILURE = 'FETCH_TRANSCRIPT_DATA_FAILURE';
export const SAVE_TRANSCRIPT_DATA_BEGIN = 'SAVE_TRANSCRIPT_DATA_BEGIN';
export const SAVE_TRANSCRIPT_DATA_SUCCESS = 'SAVE_TRANSCRIPT_DATA_SUCCESS';
export const SAVE_TRANSCRIPT_DATA_URL_SUCCESS = 'SAVE_TRANSCRIPT_DATA_URL_SUCCESS';
export const SAVE_TRANSCRIPT_DATA_FAILURE = 'SAVE_TRANSCRIPT_DATA_FAILURE';
export const SAVE_TRANSCRIPT_DATA_URL_FAILURE = 'SAVE_TRANSCRIPT_DATA_URL_FAILURE';
export const RESET_EDIT_TRANSCRIPT_SINCE_LAST_SAVED =
  'RESET_EDIT_TRANSCRIPT_SINCE_LAST_SAVED';
export const EDIT_TRANSCRIPTION = 'EDIT_TRANSCRIPTION';
export const IS_TRANSCRIPT_FETCHED = 'IS_TRANSCRIPT_FETCHED';
export const HANDLE_KEY_STROKES = 'HANDLE_KEY_STROKES';
export const HANDLE_KEY_STROKES_FROM_DB = 'HANDLE_KEY_STROKES_FROM_DB';
export const SAVE_TRANSCRIPT_SUGGESTIONS = 'SAVE_TRANSCRIPT_SUGGESTIONS';

// export const SET_IS_CORRECTION_STEP = 'SET_IS_CORRECTION_STEP';
// export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
// export const SET_INITIAL_STEP = 'SET_INITIAL_STEP';
// export const SET_IS_NEXT_STEP = 'SET_IS_NEXT_STEP';

export const fetchTranscriptionData =
  (notificationsDispatcher, params) => async (dispatch) => {
    try {
      const { id: ident, token } = params;

      dispatch({ type: FETCH_TRANSCRIPT_DATA_BEGIN });

      const response = await fetch(
        `${Config.apiUrl}/transcription/get-data-for-textarea-step`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          method: 'post',
          body: JSON.stringify({ ident }),
        }
      ).then((res) => res.json());

      if (response.status !== 'OK') {
        throw new Error();
      }

      dispatch({
        type: UPDATE_TIME_STAMP,
        payload: {
          data: { videotimestamp: response.data.videotimestamp },
        },
      });

      dispatch({
        type: FETCH_TRANSCRIPT_DATA_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: IS_TRANSCRIPT_FETCHED,
        payload: { isFetched: true },
      });
    } catch (error) {
      console.error(error);

      notificationsDispatcher({
        type: ADD_NOTIFICATION,
        payload: {
          message: {
            title: 'Failed to load project.',
            content: `Check that the project identifier in the address bar is valid then try again.
                      If the problem persists, please contact technical support.`,
          },
          timeout: 100000,
          intent: 'danger',
          icon: 'error',
        },
      });

      dispatch({ type: FETCH_TRANSCRIPT_DATA_FAILURE, payload: error });
    }
  };

  export const saveTranscriptionDataFromSessionStorage =
  (notificationsDispatcher, params) => async (dispatch, getState) => {
    try {
      return;
      const { data: { videotimestamp } } = getState().app;

      const { id: ident, token } = params;

      const {
        data: { content, finished, keyStrokes , keyStrokesFromDB},
      } = JSON.parse(sessionStorage.getItem("transcriptionData"));

      if (!content) {
        notificationsDispatcher({
          type: ADD_NOTIFICATION,
          payload: {
            message: {
              title: `At least one character must be entered before saving.`,
            },
            timeout: 5000,
            intent: 'warning',
            icon: 'warning-sign',
          },
        });

        return;
      }

      dispatch({ type: SAVE_TRANSCRIPT_DATA_BEGIN });

      let offline = 1;
      const keyStrokesToDB = Number(keyStrokesFromDB) + Number(keyStrokes);

      const response = await fetch(
        `${Config.apiUrl}/transcription/save-textarea-data`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${token}`,
          },
          method: 'post',
          body: JSON.stringify({ ident, content, videotimestamp, finished, keyStrokesToDB, offline }),
        }
      ).then((res) => res.json());

      if (response.status !== 'OK') {
        throw new Error(response.msg);
      }
      notificationsDispatcher({
        type: ADD_NOTIFICATION,
        payload: {
          message: {
            title: 'The project stored locally. has been saved successfully.',
            content: `Please check your internet connection and try again later.`,
          },
          timeout: 3000,
          intent: 'success',
          icon: 'tick',
        },
      });

      dispatch({
        type: SAVE_TRANSCRIPT_DATA_SUCCESS,
        payload: response.data,
      });

      if (sessionStorage.getItem("transcriptionData")) {
        sessionStorage.removeItem("transcriptionData");
      }
    } catch (error) {
      console.error(error);

      notificationsDispatcher({
        type: ADD_NOTIFICATION,
        payload: {
          message: {
            title: 'Project backup failed.',
            content: `Failed to save the project stored locally. Please try again later. If the problem persists, please contact technical support.`,
          },
          timeout: 100000,
          intent: 'danger',
          icon: 'error',
        },
      });

      dispatch({ type: SAVE_TRANSCRIPT_DATA_FAILURE, payload: error });
    }
  };

export const saveTranscriptionData =
  (notificationsDispatcher, navigate, params) => async (dispatch, getState) => {
    try {
      /*if (sessionStorage.getItem("transcriptionData")) {
        await saveTranscriptionDataFromSessionStorage(notificationsDispatcher, params)(dispatch, getState);
      }*/
      const { id: ident, token } = params;

      const {
        data: { content, finished, keyStrokes, keyStrokesFromDB },
      } = getState().transcription;

      const {
        data: { videotimestamp, step: nextStep },
        isNextStep,
      } = getState().app;

      if (!content) {
        notificationsDispatcher({
          type: ADD_NOTIFICATION,
          payload: {
            message: {
              title: `At least one character must be entered before saving.`,
            },
            timeout: 5000,
            intent: 'warning',
            icon: 'warning-sign',
          },
        });

        return;
      }

      // if (process.env.NODE_ENV === 'development') return;

      dispatch({ type: SAVE_TRANSCRIPT_DATA_BEGIN });

      const keyStrokesToDB = Number(keyStrokesFromDB) + Number(keyStrokes);

      const response = await fetch(
        `${Config.apiUrl}/transcription/save-textarea-data`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          method: 'post',
          body: JSON.stringify({
            ident,
            content,
            videotimestamp,
            finished,
            keyStrokesToDB,
          }),
        }
      ).then((res) => res.json());

      if (response.status !== 'OK') {
        throw new Error(response.msg);
      }

      if (isNextStep && !finished) {
        dispatch(setIsNextStep(false));

        navigate(nextStep.path);
        return;
      }

      const { editeururl } = response.data;

        dispatch({
          type: SAVE_TRANSCRIPT_SUGGESTIONS,
          payload: response.data,
        });
        // If we want to put the notifications for suggestions again
        // for (let index = 0; index < suggestions.length; index++) {
        //   notificationsDispatcher({
        //     type: ADD_NOTIFICATION,
        //     payload: {
        //       message: {
        //         title: `Suggestion No. ${index + 1}`,
        //         content: suggestions[index],
        //       },
        //       timeout: 100000,
        //       intent: 'primary',
        //       icon: 'error',
        //     },
        //   });
        // }

      if (finished && editeururl) {
        dispatch({
          type: SAVE_TRANSCRIPT_DATA_URL_SUCCESS,
          payload: response.data,
        });
      }

      if (finished && !editeururl) {
        dispatch({
          type: SAVE_TRANSCRIPT_DATA_URL_FAILURE,
          payload: {
            data: { finished: false },
            error: 'editor url empty or undefined',
          },
        });

        notificationsDispatcher({
          type: ADD_NOTIFICATION,
          payload: {
            message: {
              title: `Redirecting to the next step (Editor) failed.`,
              content: `Please try again later. If the problem persists, please contact technical support.`,
            },
            timeout: 100000,
            intent: 'danger',
            icon: 'error',
          },
        });
      }

      dispatch({ type: RESET_EDIT_TRANSCRIPT_SINCE_LAST_SAVED });

      notificationsDispatcher({
        type: ADD_NOTIFICATION,
        payload: {
          message: {
            title: 'The project has been saved successfully.',
          },
          timeout: 3000,
          intent: 'success',
          icon: 'tick',
        },
      });

      dispatch({
        type: SAVE_TRANSCRIPT_DATA_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);

      if (sessionStorage.getItem("transcriptionData")) {
        sessionStorage.removeItem("transcriptionData");
      }
      sessionStorage.setItem('transcriptionData', JSON.stringify(getState().transcription));

      //dispatch({ type: RESET_EDIT_TRANSCRIPT_SINCE_LAST_SAVED });

      notificationsDispatcher({
        type: ADD_NOTIFICATION,
        payload: {
          message: {
            title: 'Project backup failed.',
            content: `Please try again later. If the problem persists, please contact technical support.`,
          },
          timeout: 100000,
          intent: 'danger',
          icon: 'error',
        },
      });

      dispatch({ type: SAVE_TRANSCRIPT_DATA_FAILURE, payload: error });
    }
  };

export const editTranscription = (transcription) => (dispatch) => {
  dispatch({
    type: EDIT_TRANSCRIPTION,
    payload: {
      data: { ...transcription },
    },
  });
};

export const setTranscriptionFetched = (isFetched) => (dispatch) => {
  dispatch({
    type: IS_TRANSCRIPT_FETCHED,
    payload: {
      isFetched,
    },
  });
};

export const setKeyStrokes = (counter) => (dispatch) => {
  dispatch({
    type: HANDLE_KEY_STROKES,
    payload: counter,
  });
};

// // export const setIsCorrectionStep = (isCorrectionStep) => (dispatch) => {
// //   dispatch({
// //     type: SET_IS_CORRECTION_STEP,
// //     payload: {
// //       isCorrectionStep,
// //     },
// //   });
// // };

// export const setCurrentStep = (step) => (dispatch) => {
//   dispatch({
//     type: SET_CURRENT_STEP,
//     payload: {
//       step,
//     },
//   });
// };

// export const setInitialStep = (initialStep) => (dispatch) => {
//   dispatch({
//     type: SET_INITIAL_STEP,
//     payload: {
//       initialStep,
//     },
//   });
// };

// export const setIsNextStep = (isNextStep) => (dispatch) => {
//   dispatch({
//     type: SET_IS_NEXT_STEP,
//     payload: {
//       isNextStep,
//     },
//   });
// };
