// vendors
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Dialog } from '@blueprintjs/core';

const Container = styled.div`
  padding: 0 24px;
`;

const ButtonWithDialog = ({ buttonText, icon, intent, children, ...rest }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleButtonClick = React.useCallback(
    () => setIsOpen(!isOpen),
    [isOpen]
  );
  const handleClose = React.useCallback(() => setIsOpen(false), []);

  return (
    <>
      <Button
        text={buttonText}
        icon={icon}
        intent={intent}
        onClick={handleButtonClick}
        outlined
      />
      <Dialog
        isOpen={isOpen}
        onClose={handleClose}
        canEscapeKeyClose
        canOutsideClickClose
        enforceFocus
        shouldReturnFocusOnClose
        usePortal
        {...rest}
      >
        <Container>{children}</Container>
      </Dialog>
    </>
  );
};

ButtonWithDialog.propTypes = {
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string.isRequired,
  icon: PropTypes.string,
  intent: PropTypes.string,
};

ButtonWithDialog.defaultProps = {
  icon: null,
  intent: null,
};

export default ButtonWithDialog;
