import styled, { css } from 'styled-components';
import { Colors } from '@blueprintjs/core';

export const noPaddingTopStyle = css`
  && {
    padding-top: 0;
  }
`;

export const Table = styled.table`
  width: 100%;
  margin-top: 1.5rem;

  font-weight: 400;
  font-size: 16px;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #0b0c0c;

  border-spacing: 0;
  border-collapse: collapse;
`;

export const TableBody = styled.tbody`
  > * + * {
    border-top: 1px solid ${Colors.LIGHT_GRAY1};
  }
`;

export const TableRow = styled.tr``;

export const TableRowLabel = styled.tr`
  font-weight: 600;
  font-size: 0.875em;
  text-transform: uppercase;

  th {
    padding: 25px 0 15px;
  }
`;

export const TableHead = styled.th`
  margin: 0;
  padding: 10px 20px 10px 0;

  font-weight: 600;
  text-align: left;
  vertical-align: top;
`;

export const TableCell = styled.td`
  padding: 10px 20px 10px 0;

  text-align: left;
  vertical-align: top;

  :last-child {
    padding-right: 0;
  }
`;
