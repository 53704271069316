export const getSelectionRange = () => {
  const selection = window.getSelection();
  if (!selection || selection.rangeCount === 0) return null;

  return selection.getRangeAt(0);
};

export const getSelectionPosition = () => {
  const range = getSelectionRange();

  if (!range) return null;

  const { top, left } = range.getBoundingClientRect();

  return {
    top,
    left,
  };
};

export const getTranslationSelectionPosition = () => {
  const range = getSelectionRange();

  if (!range || !range.endContainer.querySelector('textarea')) return null;

  let { left, top } = range.endContainer.querySelector('textarea').getBoundingClientRect();
  top += 25; //Adding padding

  return {
    top,
    left,
  };
};

export const getInsertRange = (editorState) => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const anchorKey = selection.getAnchorKey();
  const end = selection.getAnchorOffset();
  const block = content.getBlockForKey(anchorKey);
  const text = block.getText();
  const start = text.substring(0, end).lastIndexOf(' ') + 1;

  return {
    start,
    end,
  };
};

/**
 * Check if current block text is empty
 *
 * @param editorState
 * @returns {boolean}
 */
export function isCurrentTextEmpty(editorState) {
  const selectionState = editorState.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const currentText = currentContentBlock.getText();
  return currentText.length === 0;
}
