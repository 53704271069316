// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog } from '@blueprintjs/core';
import { hideVisually } from 'polished';
import 'styled-components/macro';

// utils
import truncate from '../../../utils/truncate';
import {
  capitalizeFirstLetter,
  isFirstLetterCapitalized,
} from './Suggestions.utils';

// styles
import { ButtonWrapper, List, ListItem, Nav } from './Suggestions.styles';

const MAX_CHAR = 60;

const Suggestions = ({
  position,
  text,
  suggestions,
  selectedSuggestion,
  onSelect,
  onClose,
  ...rest
}) => {
  const renderListItem = (item, index) => {
    const splitIndex = item
      .toLowerCase()
      .split('')
      .findIndex((c, i) => c !== text.toLowerCase()[i]);

    const first = item.slice(0, splitIndex);
    let last = item.slice(splitIndex, item.length);
    last = truncate(last, MAX_CHAR, true);

    const normalizedItem = isFirstLetterCapitalized(text)
      ? capitalizeFirstLetter(item)
      : item;

    const normalizedFirst = isFirstLetterCapitalized(text)
      ? capitalizeFirstLetter(first)
      : first;

    const handleClick = () => {
      onSelect(normalizedItem);
    };

    const selected = index === selectedSuggestion;

    return (
      <ListItem key={item} onMouseDown={handleClick} $selected={selected}>
        {normalizedFirst}
        <b dangerouslySetInnerHTML={{ __html: last }} />
      </ListItem>
    );
  };

  return (
    <Dialog
      style={{ ...position }}
      isOpen={suggestions.length > 0}
      onClose={onClose}
      hasBackdrop={false}
      autoFocus={false}
      enforceFocus={false}
      shouldReturnFocusOnClose={true}
      canEscapeKeyClose={true}
      usePortal={true}
      transitionDuration={0}
      aria-labelledby="suggestions-title"
      backdropClassName="no-backdrop"
      css={`
        position: absolute;
        background: none;
        box-shadow: none;
        margin: 0;
      `}
    >
      <h2 id="suggestions-title" css={hideVisually}>
        Liste de mots suggérés par le lexique
      </h2>

      <Nav>
        <List>
          {suggestions.map((suggestion, index) =>
            renderListItem(suggestion, index)
          )}
        </List>

        <ButtonWrapper>
          <Button minimal small icon="cross" onMouseUp={onClose} />
        </ButtonWrapper>
      </Nav>
    </Dialog>
  );
};

Suggestions.propTypes = {
  position: PropTypes.shape({}),
  text: PropTypes.string,
  suggestions: PropTypes.arrayOf(PropTypes.string),
  selectedSuggestion: PropTypes.number,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
};
Suggestions.defaultProps = {
  position: undefined,
  text: '',
  suggestions: [],
  selectedSuggestion: 0,
  onSelect: () => {},
  onClose: () => {},
};

export default Suggestions;
