// vendors
import React, { useCallback } from 'react';

// components
import Controls from './Controls';

// context
import { useVideoPlayer } from './VideoPlayer.context.js';

// styles
import { Container, PlayerWrapper, StyledReactPlayer } from './VideoPlayer.styles';

const VideoPlayer = ({ src, videoExists, ...rest }) => {
  const {
    muted,
    volume,
    playbackRate,
    isPlaying,
    playerRef,
    setIsPlaying,
    setDuration,
    setProgress,
  } = useVideoPlayer();

  const handlePlay = useCallback(() => {
    if (isPlaying) return;

    setIsPlaying(true);
  }, [isPlaying, setIsPlaying]);

  const handlePause = useCallback(() => {
    if (isPlaying) return;

    setIsPlaying(false);
  }, [isPlaying, setIsPlaying]);

  const handleProgress = useCallback(
    (progress) => {
      setProgress((prev) => ({
        ...prev,
        ...progress,
      }));
    },
    [setProgress]
  );

  const handleDuration = useCallback(
    (duration) => {
      setDuration(duration);
    },
    [setDuration]
  );

  // console.log('RENDERING VIDEOPLAYER');

  return (
    <Container {...rest}>
      <PlayerWrapper>
        {!videoExists && (
          <img
            src="https://soustitreur.com/images/processingassets/audioseulement.png"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
            alt="Audio Only"
          />
        )}
        <StyledReactPlayer
          ref={playerRef}
          url={src}
          volume={volume}
          muted={muted}
          playing={isPlaying}
          playbackRate={playbackRate}
          onPlay={handlePlay}
          onPause={handlePause}
          onProgress={handleProgress}
          onDuration={handleDuration}
          progressInterval={60}
          width="100%"
          height="100%"
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload',
                onContextMenu: function(e) {
                  e.preventDefault();
                  return false;
                },
              }
            }
          }}
        />
      </PlayerWrapper>

      <Controls />
    </Container>
  );
};

export default VideoPlayer;
