// vendors
import styled from 'styled-components';
import { Button, Tag as BpTag } from '@blueprintjs/core';
import {
  Menu as BpMenu,
  MenuDivider as BpMenuDivider,
  MenuItem as BpMenuItem,
} from '@blueprintjs/core';

// styles
import { unstyledList } from '../../../styles/global';

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-width: 220px;
  max-width: 320px;
  background-color: var(--color-white);
  border-radius: inherit;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > * {
    margin-block-end: 0;
  }

  > * + * {
    margin-block-start: 0.85rem;
  }
`;

export const Content = styled.div`
  padding: 0 12px;
`;

export const Headline = styled.h2`
  font-weight: 700;
  font-size: 0.825rem;
  padding-left: 15px;

  span {
    position: relative;
  }

  span:before {
    position: absolute;
    top: 6px;
    left: -15px;
    width: 8px;
    height: 8px;
    background-color: hsl(${({ theme }) => theme});
    border-radius: 50px;
    content: '';
  }
`;

export const Description = styled.div`
  font-size: 0.75rem;
  letter-spacing: 0.1px;
  line-height: 1.5;
`;

export const Suggestions = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  margin-bottom: 0;

  ${unstyledList}
`;

export const Suggestion = styled.li``;

export const Tag = styled(BpTag)`
  --color-green-l: 38%;

  font-weight: 700;
  letter-spacing: 0.12px;

  background-color: hsl(
    var(--color-green-h),
    var(--color-green-s),
    var(--color-green-l)
  );

  transition: background-color 0.2s;

  &&:hover,
  &&:focus {
    --color-green-l: 30%;
    background-color: hsl(
      var(--color-green-h),
      var(--color-green-s),
      var(--color-green-l)
    );
  }
`;

export const ActionButton = styled.button``;

export const CloserButton = styled.button``;

export const Menu = styled(BpMenu)`
  padding: 0;
  margin-left: 0;
  margin-right: 0;
`;

export const MenuDivider = styled(BpMenuDivider)`
  margin: 0;
`;
export const MenuItem = styled(BpMenuItem)`
  align-items: center;
  padding-left: 12px;

  font-size: 0.75rem;

  span.bp3-icon {
    margin-top: 0;
  }

  svg {
    width: 14px;
    height: 14px;
  }
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;

  margin: 0;
  padding: 0;
`;
