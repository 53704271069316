import styled from 'styled-components';

// export const Container = styled.div`
//   display: grid;
//   grid-template-columns: 2fr 3fr 1.67fr;
//   width: 100%;
//   max-width: 1414px;
//   margin: 0px auto;
// `;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Wrapper = styled.div`
  display: grid;

  /* grid-template-rows: 1fr auto; */
  /* grid-template-columns: minmax(598px, 1fr) 2fr; */

  height: 100%;
  width: 100%;
  overflow-y: auto;
`;
