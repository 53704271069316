import styled from 'styled-components';

export const Content = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  width: 100%;
  height: 100%;
  min-height: 110px;
  background-color: var(--color-white);
`;
