import React, { useContext, useMemo, useReducer } from 'react';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const REMOVE_ALL_NOTIFICATIONS = 'REMOVE_ALL_NOTIFICATIONS';

export const NotificationsContext = React.createContext();

export const useNotifications = () => useContext(NotificationsContext);

const addNotification = (state, payload = {}) => {
  const { message, timeout, intent, icon } = payload;

  const newNotification = {
    id: Date.now(),
    message,
    timeout,
    intent: intent || 'primary',
    icon: icon || '',
  };

  return {
    data: {
      ...state.data,
      notifications: [...state.data.notifications, newNotification],
    },
  };
};

const removeNotification = (state, payload = {}) => {
  const notifications = [...state.data.notifications];

  return {
    data: {
      ...state.data,
      notifications: notifications.filter(({ id }) => id !== payload.id),
    },
  };
};

const removeAllNotifications = (state) => {
  return {
    data: {
      ...state.data,
      notifications: [],
    },
  };
};

const initialState = {
  data: {
    notifications: [],
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      const notifications = [...state.data.notifications];

      const notificationFound = notifications.find(
        (t) =>
          t.message.title === action.payload.message.title ||
          t.message.content === action.payload.message.content
      );
      if (notificationFound) {
        return removeNotification(state, action.payload);
      }

      return addNotification(state, action.payload);

    case REMOVE_NOTIFICATION:
      return removeNotification(state, action.payload);

    case REMOVE_ALL_NOTIFICATIONS:
      return removeAllNotifications(state);

    default:
      throw new Error(`Invalid Notification Reducer Action: ${action.type} `);
  }
};

const initializer = () => initialState;

export const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState, initializer);

  const value = useMemo(() => {
    const { notifications } = state.data;

    return { dispatch, notifications };
  }, [state.data]);

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const withNotificationsProvider = (WrappedComponent) => (props) =>
  (
    <NotificationsProvider>
      <WrappedComponent {...props} />
    </NotificationsProvider>
  );
