// vendors
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Navbar, Alignment, Button, ButtonGroup } from '@blueprintjs/core';
import { useLocation, useSearchParams } from 'react-router-dom';
import 'styled-components/macro';

// states
import { step } from '../../states/Reducers/app';

// components
import Notifications from '../Notifications';
import ButtonWithDropdown from '../ButtonWithDropdown';

// images
import logoSousTitreur from '../../images/logo-sousTitreur.svg';

// styles
import {
  Container,
  DropdownWrapper,
  Link,
  Logo,
  LogoImg,
  NavList,
  NavListItem,
  Switch,
  Title,
  TitleContainer,
} from './Titlebar.styles';
import { hideVisually } from 'polished';

const Titlebar = ({
  autoSave,
  onAutoSave,
  onSave,
  onNextStep,
  title,
  lastStepLoading,
}) => {
  const emitAutoSave = useCallback(() => {
    if (typeof onAutoSave === 'function') onAutoSave();
  }, [onAutoSave]);

  const emitSave = useCallback(() => {
    if (typeof onSave === 'function') onSave();
  }, [onSave]);

  const emitNextStep = useCallback(() => {
    if (typeof onNextStep === 'function') onNextStep();
  }, [onNextStep]);

  const location = useLocation();
  const [searchParams] = useSearchParams();

  const isTranslation = location.pathname.includes(step.TRANSLATION);
  const pathnames = location?.pathname?.split('/').filter((p) => p);
  const id = isTranslation ? pathnames[1] : pathnames[0];
  const token = searchParams.get('token');

  const isCorrection = location?.pathname?.includes(step.CORRECTION);

  return (
    <Container>
      <Navbar>
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>
            <Logo>
              <span css={hideVisually}>SousTitreur</span>
              <LogoImg src={logoSousTitreur} alt="SousTitreur" />
            </Logo>

            <TitleContainer>
              {title ? <Title>{title}</Title> : <Title>&hellip;</Title>}

              <NavList>
                <NavListItem>
                  {isTranslation ? (
                    <Link to={`/translation/${id}?token=${token}`} end>
                      {({ isActive }) => (
                        <span className={isActive ? 'active' : undefined}>
                          Translation
                        </span>
                      )}
                    </Link>
                  ) : (
                    <Link to={`/${id}?token=${token}`} end>
                      {({ isActive }) => (
                        <span className={isActive ? 'active' : undefined}>
                          Transcription
                        </span>
                      )}
                    </Link>
                  )}
                </NavListItem>

                {isCorrection && (
                  <NavListItem>
                    <Link
                      to={
                        isTranslation
                          ? `/translation/${id}/correction?token=${token}`
                          : `/${id}/correction?token=${token}`
                      }
                      end
                      state={{ isCorrection }}
                    >
                      {({ isActive }) => (
                        <span className={isActive ? 'active' : undefined}>
                          Correction
                        </span>
                      )}
                    </Link>
                  </NavListItem>
                )}
              </NavList>
            </TitleContainer>
          </Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <ButtonGroup>
            <Button
              text="Save"
              icon="cloud"
              onClick={emitSave}
              disabled={lastStepLoading}
              outlined
              large
            />

            <ButtonWithDropdown placement="bottom-end" minimal outlined large>
              <DropdownWrapper>
                <Switch
                  checked={autoSave}
                  onChange={emitAutoSave}
                  alignIndicator="right"
                  label="Auto Save"
                  large
                />
              </DropdownWrapper>
            </ButtonWithDropdown>
          </ButtonGroup>

          <Button
            text="Save & Next"
            intent="primary"
            onClick={emitNextStep}
            loading={lastStepLoading}
            large
          />
        </Navbar.Group>
      </Navbar>

      <Notifications />
    </Container>
  );
};

Titlebar.propTypes = {
  autoSave: PropTypes.bool,
  lastStepLoading: PropTypes.bool,
  onAutoSave: PropTypes.func,
  onSave: PropTypes.func,
  onNextStep: PropTypes.func,
};
Titlebar.defaultProps = {
  autoSave: false,
  lastStepLoading: false,
  onAutoSave: () => {},
  onSave: () => {},
  onNextStep: () => {},
};

export default React.memo(Titlebar);
