import { ADD_NOTIFICATION } from '../../components/Notifications/Notifications.context';
import Config from '../../libs/Config';

export const FETCH_CORRECT_DATA_BEGIN = 'FETCH_CORRECT_DATA_BEGIN';
export const FETCH_CORRECT_DATA_SUCCESS = 'FETCH_CORRECT_DATA_SUCCESS';
export const FETCH_CORRECT_DATA_FAILURE = 'FETCH_CORRECT_DATA_FAILURE';

export const fetchCorrectionData = (notificationsDispatcher, params) => async (dispatch) => {
  try {
    // const url = new URL(document.location);
    // const ident = url.pathname.slice(1);
    // const token = url.searchParams.get('token');
    const { id: ident, token, content } = params;

    dispatch({ type: FETCH_CORRECT_DATA_BEGIN });

    const response = await fetch(`${Config.apiUrl}/transcription/do-spell-checker`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'post',
      body: JSON.stringify({ ident, content }),
    }).then((res) => res.json());

    if (response.status !== 'OK') {
      throw new Error(response.msg);
    }

    dispatch({
      type: FETCH_CORRECT_DATA_SUCCESS,
      payload: { corrections: [...response.data], content },
    });
  } catch (error) {
    console.error(error);

    notificationsDispatcher({
      type: ADD_NOTIFICATION,
      payload: {
        message: {
          title: `Moving to the next step (Correction) failed`,
          content: `Please try again later. If the problem persists, please contact technical support.`,
        },
        timeout: 100000,
        intent: 'danger',
        icon: 'error',
      },
    });

    dispatch({ type: FETCH_CORRECT_DATA_FAILURE, payload: error });
  }
};

export const getFakeCorrectionsData = (notificationsDispatcher, params) => async (dispatch) => {
  try {
    const { content } = params;

    const response = await fetch('http://localhost:3000/data/corrections.json', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json());

    if (response.status !== 'OK') {
      throw new Error(response.msg);
    }

    dispatch({
      type: FETCH_CORRECT_DATA_SUCCESS,
      payload: { corrections: [...response.data], content },
    });
  } catch (error) {
    console.error(error);

    notificationsDispatcher({
      type: ADD_NOTIFICATION,
      payload: {
        message: {
          title: `Moving to the next step (Correction) failed`,
          content: `Please try again later. If the problem persists, please contact technical support.`,
        },
        timeout: 100000,
        intent: 'danger',
        icon: 'error',
      },
    });

    dispatch({ type: FETCH_CORRECT_DATA_FAILURE, payload: error });
  }
};
