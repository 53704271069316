import { createStore, applyMiddleware } from 'redux';
import rootReducer from './Reducers/index.js';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';

const composedEnhancer = composeWithDevTools(applyMiddleware(ReduxThunk));

const store = createStore(rootReducer, composedEnhancer);

export default store;
