// vendors
import React, { useCallback, useContext, useRef, useState } from 'react';

export const VideoPlayerContext = React.createContext();

export const useVideoPlayer = () => useContext(VideoPlayerContext);

export const playbackRateOptions = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

export const VideoPlayerProvider = ({ children }) => {
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(null);
  const [volume, setVolume] = useState(0.5);
  const [muted, setMuted] = useState(false);
  const [progress, setProgress] = useState({
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    loadedSeconds: 0,
  });
  const playerRef = useRef(null);
  const pauseTimerRef = useRef(null);

  const toggleIsPlaying = useCallback(() => {
    setIsPlaying(!isPlaying);
  }, [isPlaying]);

  const increasePlaybackRate = useCallback(() => {
    const playbackRateIndex = playbackRateOptions.findIndex(
      (value) => value === playbackRate
    );

    if (playbackRateIndex === playbackRateOptions.length - 1) return;

    setPlaybackRate(playbackRateOptions[playbackRateIndex + 1]);
  }, [playbackRate]);

  const decreasePlaybackRate = useCallback(() => {
    const playbackRateIndex = playbackRateOptions.findIndex(
      (value) => value === playbackRate
    );

    if (playbackRateIndex === 0) return;

    setPlaybackRate(playbackRateOptions[playbackRateIndex - 1]);
  }, [playbackRate]);

  const seekToPercentage = useCallback(
    (fraction) => {
      setProgress((prev) => ({
        ...prev,
        played: fraction,
        // playedSeconds: (duration / fraction) * 100,
        playedSeconds: duration * fraction,
      }));

      playerRef.current.seekTo(fraction);
    },
    [setProgress, duration]
  );

  const seekToSeconds = useCallback(
    (secs) => {
      setProgress((prev) => ({
        ...prev,
        played: secs / duration,
        playedSeconds: secs,
      }));

      playerRef.current.seekTo(secs, 'seconds');
    },
    [duration, setProgress]
  );

  const seekForward = useCallback(
    (sec) => {
      if (progress.playedSeconds === duration) return;

      seekToSeconds(
        duration - sec > progress.playedSeconds
          ? progress.playedSeconds + sec
          : duration
      );
    },
    [duration, progress, seekToSeconds]
  );

  const seekBackward = useCallback(
    (sec) => {
      if (progress.playedSeconds === 0) return;

      seekToSeconds(sec < progress.playedSeconds ? progress.playedSeconds - sec : 0);
    },
    [progress, seekToSeconds]
  );

  const playVideoWithDuration = useCallback(
    (startTime, endTime) => {
      if (duration && startTime >= 0) {
        if (endTime && endTime > startTime) {
          const playbackDuration = endTime - startTime;
          seekToSeconds(startTime);
          setIsPlaying(true);

          if (playbackDuration > 0) {
            const timerId = setTimeout(() => {
              setIsPlaying(false);
            }, playbackDuration * 1000);

            if (pauseTimerRef.current) {
              clearTimeout(pauseTimerRef.current);
            }
            pauseTimerRef.current = timerId;
          } else {
            setIsPlaying(false);
          }
        }
      }
    },
    [duration, seekToSeconds, setIsPlaying]
  );

  return (
    <VideoPlayerContext.Provider
      value={{
        playbackRate,
        isPlaying,
        progress,
        duration,
        volume,
        muted,
        setMuted,
        setVolume,
        setPlaybackRate,
        setIsPlaying,
        toggleIsPlaying,
        increasePlaybackRate,
        decreasePlaybackRate,
        seekToSeconds,
        seekToPercentage,
        setDuration,
        setProgress,
        seekForward,
        seekBackward,
        playVideoWithDuration,
        playerRef,
      }}
    >
      {children}
    </VideoPlayerContext.Provider>
  );
};

export const withVideoPlayerProvider = (WrappedComponent) => (props) =>
  (
    <VideoPlayerProvider>
      <WrappedComponent {...props} />
    </VideoPlayerProvider>
  );
