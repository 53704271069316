import { H5, OL } from '@blueprintjs/core'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components';
import 'styled-components/macro';

const Section = styled.div`
  min-height: 20vh;
  max-height: 25vh;
  padding-top: 1rem;

  overflow-y: scroll;
  overflow-x: scroll;
  padding: 1.5rem 1.25rem 2.5rem;
  box-shadow: 0 1px 2px var(--shadow-color);
  grid-area: right;
  grid-column: 2 / 3;
`;

const NotesSection = ({notes, lexiques}) => {

  const [lexique, setLexique] = useState([]);

  const handleLexiques = useCallback(() => {
    lexiques.map((lex) => {
      if (lex.isLexique === 1) {
        return setLexique((prev) => [...prev, ...lex.word.split(/\r?\n/)]);
      }
    })
  }, [lexiques]);

  useEffect(() => {
    handleLexiques();
  }, [handleLexiques])

  return (
    <Section>
      {notes && notes.length > 0 ? (
      <>
        <H5>NOTES:</H5>
        {notes.map((note) => (
          <OL key={`${note.word}-${note.desc}-1`}>
            Word: {note.word} , Description: {note.desc}
          </OL>
        ))}
      </>
      ) : null}

    {lexique && lexique.length > 0 && lexique[0] !== '' ? (
      <>
        <H5>LEXIQUES:</H5>
        {lexique.map((lexi, index) => (
            lexi !== '' && <OL key={`${lexi}-${index}`}>{lexi}</OL>
          ))}
      </>
    ) : null}
    </Section>
  );
}

export default NotesSection
