// vendors
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import useSpeechToText from 'react-hook-speech-to-text';
import 'styled-components/macro';

// configs
import Config from '../../libs/Config';

// actions
import { HANDLE_BUTTON_CLICKS } from '../../states/Actions';

/**
 * @module SpeechToText
 * @description
 * The **SpeechToText** component allows to add speech-to-text functionality
 * to any component/view
 *
 * @example
 * <div>
 *  <SpeechToText initialValue={value} onChange={handleTextChange} />
 *  <textarea value={value} ...>
 * <div>
 *
 * NOTE: When used multiple times like in a long list of items,
 * try to initialize it after an event (i.e. focus, click) or any React states
 * in order to prevent rendering performance issues.
 *
 * @example
 * <div>
 *  {focused && (
 *    <SpeechToText initialValue={value} onChange={handleTextChange} />
 *  )}
 *  <textarea value={value} ...>
 * <div>
 */
const SpeechToText = ({ initialValue, onChange, onRecording, ...rest }) => {
  const [speechText, setSpeechText] = useState('');
  const [active, setActive] = useState(true);
  const [value, setValue] = useState('');
  const [activeHoldTimeoutId, setActiveHoldTimeoutId] = useState(null);
  const [continuation, setContinuation] = useState('');
  const [stop, setStop] = useState(false);

  const dispatch = useDispatch();
  const lang = useSelector((state) => state.translation.data.lang);

  const timerRef = useRef(1500);

  const {
    error,
    isRecording,
    results,
    setResults,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    timeout: 3000,
    continuous: true,
    useLegacyResults: false,
    googleApiKey: Config.googleApiKey,
    crossBrowser: true,
    useOnlyGoogleCloud: true,
    googleCloudRecognitionConfig: {
      languageCode: lang.replaceAll('_', '-'),
      useEnhanced: true,
      enableAutomaticPunctuation: true,
    },
    onStartSpeaking: () => {
      setContinuation(speechText.endsWith('.') ? '..' : '...');
    },
    onStoppedSpeaking: () => {
      if (stop) return setContinuation('');
      setTimeout(() => {
        setContinuation('');
      }, 1100);
    },
  });

  const handleStartSpeechToText = useCallback(() => {
    dispatch({
      type: HANDLE_BUTTON_CLICKS,
    });
    setActive(false);
    setStop(false);
    startSpeechToText();
    setResults([]);
  }, [dispatch, setResults, startSpeechToText]);

  const handleHoldSpeechToText = useCallback(
    (event) => {
      setStop(false);
      let held = false;

      if (event.type === 'mousedown') {
        setValue(initialValue);

        setActiveHoldTimeoutId(
          setTimeout(() => {
            held = true;
            if (held) {
              dispatch({
                type: HANDLE_BUTTON_CLICKS,
              });
              startSpeechToText();
              setResults([]);
            }
          }, 200)
        );
      } else {
        held = false;
        clearTimeout(activeHoldTimeoutId);

        if (!isRecording) handleStartSpeechToText();
        if (isRecording) setStop(true);
      }
    },
    [
      initialValue,
      dispatch,
      startSpeechToText,
      setResults,
      activeHoldTimeoutId,
      isRecording,
      handleStartSpeechToText,
    ]
  );

  const handleStopSpeechToText = useCallback(() => {
    timerRef.current = 0;
    setActive(true);
    setStop(true);
  }, []);

  if (error) console.log(error);

  useEffect(() => {
    if (stop && results.length > 0) stopSpeechToText();
    if (stop) setContinuation('');
  }, [stop, stopSpeechToText, results]);

  const emitChange = useCallback(
    (content) => {
      if (typeof onChange === 'function') onChange(content);
    },
    [onChange]
  );

  const emitRecording = useCallback(() => {
    if (typeof onRecording === 'function') onRecording();
  }, [onRecording]);

  useEffect(() => {
    if (isRecording) emitRecording();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecording]);

  useEffect(() => {
    if (!isRecording) return;

    const transcript = results.map((result) => result.transcript).join(' ');
    setSpeechText(transcript);

    const content = value + ' ' + transcript + continuation;

    emitChange(content);
  }, [results, continuation, value, isRecording, emitChange]);

  return (
    <div {...rest}>
      {isRecording && !active ? (
        <>
          <p
            css={`
              display: inline-block;
              margin: auto 1%;
              color: grey;
              font-size: 9pt;
            `}
          >
            Listening and Writing...
          </p>
          <Button
            small
            data-toggle="tooltip"
            data-placement="top"
            title="Stop"
            icon="stop"
            intent="danger"
            onClick={handleStopSpeechToText}
            style={{ margin: 'auto 1%' }}
          />
        </>
      ) : (
        <>
          <p
            css={`
              display: inline-block;
              margin: auto 1%;
              color: grey;
              font-size: 9pt;
              user-select: none;
            `}
          >
            Press or hold to record
          </p>
          <Button
            small
            data-toggle="tooltip"
            data-placement="top"
            icon={<FontAwesomeIcon icon={faMicrophone} />}
            intent="success"
            title="Press or hold to record"
            onMouseDown={handleHoldSpeechToText}
            onMouseUp={handleHoldSpeechToText}
            style={{ margin: 'auto 1%' }}
          />
        </>
      )}
    </div>
  );
};

SpeechToText.propTypes = {
  /**
   * Initial value where speech-to-text starts from
   */
  initialValue: PropTypes.string.isRequired,
  /**
   * Callback function called when speech-to-text is done (i.e. update input/textarea value)
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Callback function called when speech-to-text is recording
   */
  onRecording: PropTypes.func,
};
SpeechToText.defaultProps = {
  onRecording: () => {},
};

export default React.memo(SpeechToText);
