import { combineReducers } from 'redux';

import app from './app';
import transcription from './transcription';
import translation from './translation';
import correction from './correction';

const reducers = combineReducers({
  app,
  transcription,
  translation,
  correction,
});

export default reducers;
