/* eslint-disable jsx-a11y/anchor-is-valid */
// vendors
import React from 'react';
import PropTypes from 'prop-types';

// styles
import { LinkButton } from './ErrorHighlight.styles';

const ErrorHighlight = ({ elementRef, active, color, children, ...rest }) => {
  return (
    <LinkButton
      tabIndex="0"
      role="button"
      aria-haspopup="true"
      aria-expanded={active}
      ref={elementRef}
      $color={color}
      {...rest}
    >
      {children}
    </LinkButton>
  );
};

ErrorHighlight.propTypes = {
  children: PropTypes.node.isRequired,
  elementRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  active: PropTypes.bool,
  color: PropTypes.string,
};
ErrorHighlight.defaultProps = {
  elementRef: undefined,
  color: undefined,
  active: false,
};

export default ErrorHighlight;
