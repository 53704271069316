import {
  SET_CURRENT_STEP,
  SET_INITIAL_STEP,
  SET_IS_NEXT_STEP,
  UPDATE_TIME_STAMP,
} from '../Actions/app';

export const step = {
  TRANSCRIPTION: 'transcription',
  TRANSLATION: 'translation',
  CORRECTION: 'correction',
};

const initialState = {
  data: {
    videotimestamp: 0,
    initialStep: '',
    step: {
      name: '',
      path: '',
    },
  },
  isNextStep: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_STEP:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    case SET_INITIAL_STEP:
      return {
        ...state,
        ...action.payload,
      };

    case SET_IS_NEXT_STEP:
      return {
        ...state,
        ...action.payload,
      };

    case UPDATE_TIME_STAMP:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };

    default:
      return state;
  }
};

export default reducer;
