// vendors
import { Button as BpButton, Colors, Slider } from '@blueprintjs/core';
import styled, { css } from 'styled-components';

export const sliderStyles = css`
  height: 100%;

  .bp3-slider-label {
    transform: translate(-50%, -20px);
    visibility: hidden;
  }

  .bp3-slider-axis .bp3-slider-label {
    display: none;
  }

  .bp3-slider-handle {
    border-radius: 50px;

    :focus {
      outline: 0;
    }

    :hover > .bp3-slider-label,
    :focus > .bp3-slider-label {
      visibility: visible;
    }
  }
`;

export const Container = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0;

  width: 100%;

  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(13, 13, 13, 0.8) 100%
  );
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;

  align-items: center;
  width: 100%;

  > :not(:first-child) {
    padding-bottom: 5px;
  }
`;

export const Button = styled(BpButton)`
  && {
    color: ${Colors.WHITE};
    font-size: 12px;
    font-weight: 700;
  }

  &&:focus,
  &&:hover {
    outline: none;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 3px;
    color: ${Colors.WHITE};
  }

  > .bp3-icon {
    color: ${Colors.WHITE};
  }

  .bp3-icon > svg {
    width: 20px;
    height: 20px;
  }
`;

export const VolumeSliderWrapper = styled.div`
  padding: 10px 0;
  margin: 0;

  transition: margin 400ms;

  will-change: margin;
`;

export const VolumeSlider = styled(Slider)`
  ${sliderStyles}

  min-width: 0;

  transition: min-width 400ms 100ms;

  .bp3-slider-progress.bp3-intent-primary {
    background: ${Colors.WHITE};
  }

  .bp3-slider-track,
  .bp3-slider-progress {
    height: 2px;
  }

  .bp3-slider-progress {
    background: ${Colors.WHITE};
  }

  .bp3-slider-handle {
    top: -2px;
    background-color: ${Colors.WHITE};
    transform: scale(0);

    transition: transform 500ms;
  }

  .bp3-slider-handle .bp3-slider-label {
    display: none;
  }

  will-change: min-width, transform;
`;

export const VolumeWrapper = styled.div`
  display: inline-flex;

  &:hover ${VolumeSliderWrapper}, &:focus ${VolumeSliderWrapper} {
    margin-left: 10px;
    margin-right: 16px;
  }

  &:hover ${VolumeSlider}, &:focus ${VolumeSlider} {
    min-width: 70px;

    .bp3-slider-handle {
      transform: scale(1);
    }
  }
`;

export const Timeline = styled(Slider)`
  ${sliderStyles}

  .bp3-slider-track:hover ~ .bp3-slider-handle,
  .bp3-slider-handle:hover, .bp3-slider-handle:focus {
    transform: scale(1);
  }

  .bp3-slider-progress.bp3-intent-primary {
    background: #41cf87;
    /* background-image: linear-gradient(90deg, #41cf87 0%, #acffb4 100%); */
    background-image: linear-gradient(90deg, #27c28c 0%, #8ce880 100%);
  }

  .bp3-slider-progress {
    background: ${Colors.LIGHT_GRAY2};
  }

  .bp3-slider-handle {
    transform: scale(0);

    transition: transform 250ms;
  }
`;

export const TimeStamp = styled.div`
  display: inline-flex;
  align-items: center;
  color: ${Colors.WHITE};
  font-size: 14px;
  font-weight: 600;
  min-width: 73px;
`;
