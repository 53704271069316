// vendors
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import WebFont from 'webfontloader';

import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import 'normalize.css';

// redux store
import store from './states/store';

// styles
import { GlobalStyle } from './styles/global';

import App from './app';

WebFont.load({
  google: {
    families: ['Manrope:400,500,600,700,800', 'Open Sans:500,600'],
  },
});

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />

    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  /*<div
    style={{
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      minHeight: '100vh',
      minWidth: '100vw',
    }}
  >
    <div style={{ textAlign: 'center' }}>
      <h1 style={{ marginBottom: '1rem' }}>
        Sunday August 11th, 21:00 to 23:59 New York Time, our services will be
        unavailable
      </h1>
      <img
        src="//soustitreur.com/images/logo/logo.png"
        alt="Logo"
        style={{
          height: '10rem',
        }}
      />
    </div>
  </div>,*/
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
