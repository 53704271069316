// vendors
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import 'styled-components/macro';

// actions
import { editTranscription, setKeyStrokes } from '../../../states/Actions';

// contexts
import {
  useEditor,
  withEditorProvider,
} from '../../../components/Editor/Editor.context';

// components
import { useVideoPlayer } from '../../../components/VideoPlayer';
import Editor from '../../../components/Editor';

// styles
import { hideVisuallyStyle } from '../../../styles/global';
import {
  ADD_NOTIFICATION,
  useNotifications,
} from '../../../components/Notifications/Notifications.context';

export const handlePastedText =
  (notificationsDispatcher, accessMode, isTranslation = false) => (text, html, editorState) => {
    const MAX_CHAR = 100;

    if (text.length <= MAX_CHAR || (accessMode && accessMode === isTranslation ? "PROOFREADER" : "ADMIN")) return false;

    notificationsDispatcher({
      type: ADD_NOTIFICATION,
      payload: {
        message: {
          title: `Maximum of 100 characters allowed reached (${
            text.length - MAX_CHAR
          } found )`,
          content: `Reduce the number of characters to paste into the text box and try again.`,
        },
        timeout: 10000,
        intent: 'warning',
        icon: 'warning-sign',
      },
    });

    return true;
  };

const Section = styled.section`
  max-height: calc(100vh - var(--header-height));
  padding-top: 1rem;

  overflow-y: auto;
`;

const EditorSection = ({ pauseOnTyping, ...rest }) => {
  const dispatch = useDispatch();

  const { dispatch: notificationsDispatcher } = useNotifications();

  const content = useSelector((state) => state.transcription.data.content);
  const lex = useSelector((state) => state.transcription.data.lexique);
  const lexical = lex.map(item => item.word);

  const accessMode = useSelector((state) => state.transcription.data.accessmode);
  const [counter, setCounter] = useState(0);

  const { isPlaying, setIsPlaying } = useVideoPlayer();

  const { editorState } = useEditor();

  /**
   * Update data content
   *
   * We need to use a useEffect here instead of an event on change
   * to ensure having the last text value from the editor.
   */
  useEffect(() => {
    if (!editorState) return;

    const currentContent = editorState.getCurrentContent();
    const plainText = currentContent.getPlainText();

    if (plainText !== content) {
      if (pauseOnTyping && isPlaying) setIsPlaying(false);

      dispatch(editTranscription({ content: plainText }));
    }
  }, [dispatch, editorState, content, isPlaying, pauseOnTyping, setIsPlaying]);

  const customKeyBinding = (event) => {
    if (event.type === 'keydown') {
      setCounter((prev) => prev + 1);
      //handle the counter in the keystroke new state reducer
      dispatch(setKeyStrokes(counter));
    }
  };

  return (
    <Section aria-labelledby="accessible-editor-transcribe" {...rest}>
      <h2 id="accessible-editor-transcribe" css={hideVisuallyStyle}>
        Éditeur texte de transcription
      </h2>

      <Editor
        plainText={content}
        lexical={lexical}
        onPastedText={handlePastedText(notificationsDispatcher, accessMode)}
        focusToEnd
        isTranscription = {true}
        css={`
          margin: 0 1rem 0 1rem;
          padding: 1.5rem 1.25rem 2.5rem;
          box-shadow: 0 1px 2px var(--shadow-color);
        `}
        customKeys={customKeyBinding}
      />
    </Section>
  );
};

EditorSection.propTypes = {
  pauseOnTyping: PropTypes.bool,
};
EditorSection.defaultProps = {
  pauseOnTyping: false,
};

export default withEditorProvider(EditorSection);
