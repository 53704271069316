import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchValue } from '../../states/Actions';
import {
  ADD_NOTIFICATION,
  useNotifications,
} from '../Notifications/Notifications.context';
import './inputsearch.css';

export const SearchSentence = () => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const { dispatch: notificationsDispatcher } = useNotifications();

  const children = useSelector((state) => state.translation.data.children);

  const startSearch = useCallback(() => {
    if (children[search - 1]) {
      dispatch(setSearchValue(search - 1));
    } else if (search.length <= 0) {
      return;
    } else {
      notificationsDispatcher({
        type: ADD_NOTIFICATION,
        payload: {
          message: {
            title: `The cell #${search} was not found`,
            content: `Be sure to write the correct number of cell`,
          },
          timeout: 5000,
          intent: 'warning',
          icon: 'warning-sign',
        },
      });
    }
  }, [children, search, notificationsDispatcher, dispatch]);

  const handleKeyPress = (e) => {
    if (e.key === '-') e.preventDefault();
  };

  return (
    <div style={{ marginLeft: '5%' }}>
      <label>Search for cell number</label>
      <input
        type={'number'}
        min={0}
        onKeyPress={handleKeyPress}
        style={{
          marginLeft: '2%',
          marginTop: '4px',
          width: '15%',
          background: 'transparent',
          border: '2px solid grey',
          borderRadius: '5px',
        }}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        onKeyUp={(e) => (e.key === 'Enter' ? startSearch() : null)}
      />
    </div>
  );
};
