// vendors
import {
  convertFromRaw,
  convertToRaw,
  ContentState,
  EditorState,
  SelectionState,
  Modifier,
} from 'draft-js';

export const color = {
  RED: 'red',
  YELLOW: 'yellow',
  BLUE: 'blue',
};

export const mapRuleIdToLabel = {
  TYPOS: 'Faute de frappe',
  STYLE: 'Style',
};
export const getRuleLabelFromId = (id) => {
  const defaultLabel = 'Grammaire et autres';
  return mapRuleIdToLabel[id] || defaultLabel;
};

export const mapRuleCategoryIdToColor = {
  TYPOS: color.RED,
  STYLE: color.BLUE,
};
export const getColorFromRuleCategoryId = (id) => {
  return mapRuleCategoryIdToColor[id] || color.YELLOW;
};

// filter corrections where the default correction offset
// is between the start/end offset of the current block
// then return ranges depending on correction object values
export const getEntityRanges = (corrections, blockOffset) => {
  return corrections
    .filter((c) => blockOffset.start <= c.offset && c.offset < blockOffset.end)
    .map((correction) => ({
      key: correction.id,
      length: correction.length,
      offset: correction.offset - blockOffset.start,
    }));
};

const normalizeBlocksFromCorrections = (blocks, corrections, plainText) => {
  const normalized = blocks.reduce(
    (acc, block, index, array) => {
      const previousBlock = array[index - 1];
      const previousBlockEmpty =
        previousBlock && previousBlock?.text?.length === 0;
      const isCurrentBlockEmpty = block.text.length === 0;

      const currentBlockOffset = {
        start: acc.charCount,
        end: isCurrentBlockEmpty
          ? acc.charCount
          : acc.charCount + block.text.length + 1,
      };

      if (previousBlockEmpty && !isCurrentBlockEmpty) {
        currentBlockOffset.start = acc.charCount + acc.lineBreakCount;
        currentBlockOffset.end =
          currentBlockOffset.start + block.text.length + 1;
      }

      const entityRanges = getEntityRanges(corrections, currentBlockOffset);

      acc.charCount = currentBlockOffset.end;
      acc.lineBreakCount = isCurrentBlockEmpty ? acc.lineBreakCount + 1 : 0;

      acc.blocks.push({ ...block, entityRanges });

      return acc;
    },
    { charCount: 0, lineBreakCount: 0, blocks: [] }
  );

  return normalized.blocks;
};

export const createCorrectionsEntities = (plainText, corrections) => {
  const rawContent = convertToRaw(ContentState.createFromText(plainText));
  const rawState = corrections.map((correction) => ({
    type: 'CORRECTION',
    mutability: 'MUTABLE',
    data: {
      id: correction.id,
      message: correction.message,
      replacements: [...correction.replacements],
      rulecatid: correction.rulecatid,
      ruleid: correction.ruleid,
    },
  }));

  rawContent.entityMap = [...rawState];

  rawContent.blocks = normalizeBlocksFromCorrections(
    rawContent.blocks,
    corrections,
    plainText
  );

  return convertFromRaw(rawContent);
};

// function clearEntityRanges(contentState) {
//   contentState.getBlockMap().forEach((block) => {
//     const blockKey = block.getKey();
//     const blockText = block.getText();
//     // You need to create a selection for entire length of text in the block
//     const selection = SelectionState.createEmpty(blockKey);
//     const updatedSelection = selection.merge({
//       //anchorOffset is the start of the block
//       anchorOffset: 0,
//       // focustOffset is the end
//       focusOffset: blockText.length,
//     });
//     Modifier.applyEntity(contentState, updatedSelection, null);
//   });
//   return contentState;
// }
export const removeEntitiesFromText = (editorState, text) => {
  const currentContent = editorState.getCurrentContent();

  currentContent.getBlockMap().forEach((block) => {
    const blockKey = block.getKey();
    const blockText = block.getText();
    const matches = [...blockText.matchAll(text)];

    matches.forEach((match) => {
      const selection = SelectionState.createEmpty(blockKey);
      const updatedSelection = selection.merge({
        anchorOffset: match.index,
        focusOffset: match.index + match[0].length,
      });

      Modifier.applyEntity(currentContent, updatedSelection, null);
    });
  });

  const newEditorState = EditorState.push(
    editorState,
    currentContent,
    'IGNORE-ERROR'
  );

  return newEditorState;
};

export const getTextFromBlockKey = (editorState, decoratorProps) => {
  const { blockKey, start, end } = decoratorProps;

  const currentContent = editorState.getCurrentContent();
  // Get block for current selection
  const currentBlock = currentContent.getBlockForKey(blockKey);

  // const selection = editorState.getSelection();
  // //Then based on the docs for SelectionState -
  // const start = selection.getStartOffset();
  // const end = selection.getEndOffset();
  const selectedText = currentBlock.getText().slice(start, end);

  return selectedText;
};

const findCorrectionEntities = (contentBlock, callback, contentState) => {
  return contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    if (entityKey === null) {
      return false;
    }
    return contentState.getEntity(entityKey).getType() === 'CORRECTION';
  }, callback);
};

export const getErrorCorrectionStrategy =
  () => (contentBlock, callback, contentState) => {
    findCorrectionEntities(contentBlock, callback, contentState);
  };

export const forceSelection = (editorState, selection) => {
  return EditorState.forceSelection(editorState, selection);
};

export const addCorrection = (editorState, selected, decoratorProps) => {
  const { start, end, blockKey, entityKey } = decoratorProps;
  // const selection = currentSelection.merge({
  //   anchorKey: blockKey,
  //   anchorOffset: start,
  //   focusOffset: end,
  // });
  const currentContent = editorState.getCurrentContent();
  // We need to create a selection for the entire length of text in the block
  const currentSelection = SelectionState.createEmpty(blockKey);
  let updatedSelection = currentSelection.merge({
    anchorOffset: start,
    focusOffset: end,
  });

  const contentStateWithReplacedText = Modifier.replaceText(
    currentContent,
    updatedSelection,
    selected,
    null,
    entityKey
  );

  updatedSelection = currentSelection.merge({
    anchorOffset: start,
    focusOffset: start + selected.length,
  });

  const contentStateWithoutEntity = Modifier.applyEntity(
    contentStateWithReplacedText,
    updatedSelection,
    null
  );

  const newEditorState = EditorState.push(
    editorState,
    contentStateWithoutEntity,
    'ADD-CORRECTION'
  );

  updatedSelection = currentSelection.merge({
    anchorOffset: start + selected.length,
    focusOffset: start + selected.length,
  });

  return EditorState.forceSelection(newEditorState, updatedSelection);
};

export const removeCorrection = (editorState, decoratorProps) => {
  const { start, end, blockKey } = decoratorProps;

  const currentContent = editorState.getCurrentContent();
  const currentSelection = SelectionState.createEmpty(blockKey);

  let updatedSelection = currentSelection.merge({
    anchorOffset: start,
    focusOffset: end,
  });

  const contentStateWithoutEntity = Modifier.applyEntity(
    currentContent,
    updatedSelection,
    null
  );

  let newEditorState = EditorState.push(
    editorState,
    contentStateWithoutEntity,
    'ignore-error'
  );

  updatedSelection = currentSelection.merge({
    anchorOffset: end,
    focusOffset: end,
  });

  return EditorState.forceSelection(newEditorState, updatedSelection);
};
