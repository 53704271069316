import styled, { css } from 'styled-components';
import ReactPlayer from 'react-player';
import { lessThan } from '../../utils/mediaQuery';

export const Container = styled.div`
  /* position: relative; */
  position: relative;
  /* display: flex;
  justify-content: center;
  min-width: 480px;
  min-height: 100px;
  height: 100%;
  overflow: hidden; */
  background-color: var(--color-black);
`;

export const PlayerWrapper = styled.div`
  /* position: relative;
  min-height: 100px;
  display: flex;
  justify-content: center;
  overflow: hidden; */
  position: relative;

  padding-top: ${(9 / 16) * 100}%;
`;

export const StyledReactPlayer = styled(ReactPlayer)`
  /* width: 100%;
  height: 100%; */
  position: absolute;
  top: 0;
  left: 0;
  /* aspect-ratio: 16 / 9; */
`;

export const noAspectRatioStyle = css`
  display: flex;
  justify-content: center;
  min-width: 480px;
  min-height: 100px;
  height: 100%;
  width: 100%;
  overflow: hidden;

  ${PlayerWrapper} {
    position: relative;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-height: 100px;
    padding-top: 0 !important;
    overflow: hidden;
  }

  ${StyledReactPlayer} {
    position: relative;

    /* video {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: contain;
    }*/

    ${lessThan(940)} {
      video {
        object-fit: cover;
      }
    }
  }
`;
