import { H5, OL } from '@blueprintjs/core'
import styled from 'styled-components';
import 'styled-components/macro';

const Section = styled.div`
  min-height: 10vh;
  max-height: 25vh;
  height: 100%;
  padding-top: 1rem;

  overflow-y: scroll;
  overflow-x: scroll;
  padding: 1.5rem 1.25rem 2.5rem;
  box-shadow: 0 1px 2px var(--shadow-color);
  grid-area: right;
  grid-column: 2 / 3;
`;

const SuggestionsSection = ({suggestions}) => {
  return (
    <Section>
      <H5>SUGGESTIONS:</H5>
      {suggestions &&
        suggestions.map((suggestion, index) => (
          <OL key={index}>
            {suggestion}
          </OL>
        ))}
    </Section>
  );
}

export default SuggestionsSection
